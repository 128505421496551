import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useMemo,
} from "react";

import { Platform } from "react-native";
import { Device } from "@twilio/voice-sdk";

import Engage from "~/services/engage";
import { TWILIO_VOICE_OPTIONS } from "~/constants/twilio";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import { callReducer, INIT_CALL_STATE } from "~/reducers/callReducer";
import { parsePhoneNumber } from "libphonenumber-js";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import {
  CALL_EVENT_TYPE,
  CALL_STATUS_COMMAND,
} from "../../constants/webapp-integration";

export default function useTwilioInit(identity) {
  const device = useRef(null);

  const { getUser, isDisturbMode, user } = useContext(UserContext);
  const [ready, setReady] = useState(false);
  const [token, setToken] = useState(null);
  const [iceServers, setIceServers] = useState([]);
  const [activeCall, setActiveCall] = useState(null);
  const [eventCallData, setEventCallData] = useState(null);
  const [calls, setCalls] = useState({});
  const [ignoredCalls, setIgnoredCalls] = useState([]);

  const callInvites = useMemo(() => {
    return Object.fromEntries(
      Object.entries(calls).filter((c) => {
        return c[1]?.state === "invited";
      }),
    );
  }, [JSON.stringify(calls)]);

  const acceptedCalls = useMemo(() => {
    return Object.entries(calls).filter((c) => {
      return c?.state === "accepted";
    });
  }, [JSON.stringify(calls)]);

  const updateIgnoredCalls = (newCalls) => {
    const ignoredCalls = Object.values(newCalls).filter((c) => {
      return c?.ignored;
    });
    setIgnoredCalls(ignoredCalls);
  };

  const [callState, dispatch] = useReducer(callReducer, INIT_CALL_STATE);

  const { activeNumber } = useContext(NumbersContext);

  useEffect(() => {
    const num = `${activeNumber?.id}`;

    return () => {
      if (num === activeNumber?.id) {
        setToken(null);
        setIceServers([]);
      }
    };
  }, [activeNumber?.id, setToken, setIceServers]);

  const callStatus = useMemo(() => {
    if (callState.failure) {
      return "Call failed";
    }
    if (callState.disconnected) {
      return "Call ended";
    }
    if (callState.ringing) {
      return "Ringing";
    }
    return "";
  }, [callState]);

  /**
   *
   *
   * @param {*}
   * @return {*}
   */
  const updateActiveCall = useCallback(
    (call) => {
      if (!activeCall) {
        return;
      }

      // Don't allow these properties to be changed on an existed call.
      const { destination, direction, ...data } = call;
      setActiveCall({
        ...activeCall,
        ...data,
      });
    },
    [activeCall],
  );

  useEffect(() => {
    if (eventCallData) {
      updateActiveCall(eventCallData);
    }
  }, [eventCallData]);

  const getAccessToken = useCallback(async () => {
    if (!activeNumber) {
      return;
    }

    const res = await Engage.getTwilioAccessToken(
      identity,
      activeNumber.id,
      Platform.OS,
    );

    if (res.error) {
      getUser();
      return null;
    }

    setToken(res?.token);
    setIceServers(res?.ice_servers || []);
    return res?.token;
  }, [setToken, identity, activeNumber, getUser]);

  const initialize = useCallback(async () => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      setReady(true);
    }
  }, [getAccessToken, setReady, activeNumber]);

  useEffect(() => {
    if (identity && activeNumber?.id && !token) {
      // Don't initialize until active number exists because we need it for the token
      initialize();
    }
  }, [identity, token, activeNumber?.id]);

  const addCall = (call, state, data = {}) => {
    if (!call || !call?.parameters || !call?.parameters?.CallSid) return;
    setCalls((calls) => {
      let currCalls = calls;
      currCalls[call?.parameters?.CallSid] = {
        call,
        timestamp: new Date(),
        data,
        state,
        ignored: false,
      };
      let formattedState = "incoming";
      if (state === "accepted") formattedState = "accepted";
      if (state === "accepted" && data?.direction) formattedState = "outgoing";
      if (state === "invited") formattedState = "incoming";
      emitCallEvent(
        {
          ...call,
          event: formattedState,
        },
        currCalls,
        true,
      );
      updateIgnoredCalls(currCalls);
      return currCalls;
    });
  };

  const removeCall = (call) => {
    if (!call || !call?.parameters || !call?.parameters?.CallSid) return;
    setCalls((calls) => {
      let currCalls = calls;
      if (currCalls[call?.parameters?.CallSid])
        delete currCalls[call?.parameters?.CallSid];
      updateIgnoredCalls(currCalls);
      return currCalls;
    });

    emitCallEvent({
      ...call,
      event: "ended",
    });
  };

  const activeCallCleanUp = (call) => {
    setActiveCall((currCall) => {
      if (currCall?.parameters?.CallSid === call?.parameters?.CallSid) {
        return null;
      }
      return currCall;
    });
  };

  const updateCallStatus = (call, event) => {
    if (!call || !call?.parameters || !call?.parameters?.CallSid) return;

    setActiveCall((activeCall) => {
      if (!activeCall) {
        dispatch(event);
      }
      if (call?.parameters?.CallSid === activeCall?.parameters?.CallSid) {
        dispatch(event);
      }
      return activeCall;
    });
  };

  useEffect(() => {
    if (ready && token && !device.current) {
      device.current = new Device(token, TWILIO_VOICE_OPTIONS);

      device.current.addListener("registering", (e) => {
        console.log("[useTwilioInit:Registrating]", e);
      });

      device.current.addListener("registered", (e) => {
        console.log("[useTwilioInit:RegistrationSuccess]", e);
      });

      device.current.addListener("unregistered", (e) => {
        console.log("[useTwilioInit:Unregistered]", e);
        setActiveCall(null);
        dispatch({ type: "FAIL" });
      });

      device.current.addListener("incoming", (call) => {
        console.log("[useTwilioInit:CallInvite]", call);
        addCall(call, "invited");

        const onAccept = (acceptedCall) => {
          console.log("[incoming_call:CallAccepted]", acceptedCall);
          setActiveCall(acceptedCall);
          updateCallStatus(acceptedCall, { type: "CONNECTED" });
          addCall(acceptedCall, "accepted");
        };

        const onDisconnect = (disconnectedCall) => {
          console.log("[incoming call:CallDisconnected]", disconnectedCall);
          if (disconnectedCall) {
            disconnectedCall.removeAllListeners();
            disconnectedCall.disconnect();
          }
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onReject = (rejectedCall) => {
          console.log("[incoming call:CallRejected]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onCancel = (rejectedCall) => {
          console.log("[incoming call:CallCancel]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onError = (rejectedCall) => {
          console.log("[incoming call:CallError]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        call.on("reject", onReject);
        call.on("cancel", onCancel);
        call.on("error", onError);
        call.on("accept", onAccept);
        call.on("disconnect", onDisconnect);
        updateCallStatus(call, { type: "INVITED" });
      });

      device.current.on("error", (e, callInstance) => {
        console.error("[useTwilioInit:Error]", e, callInstance);
        if (callInstance) {
          callInstance.removeAllListeners();
          callInstance.disconnect();
        }
        activeCallCleanUp(callInstance);
        removeCall(callInstance);
      });

      device.current.on("tokenWillExpire", async () => {
        const token = await getAccessToken();
        device.current.updateToken(token);
      });

      device.current.register();
    }

    return () => {
      if (device.current) {
        device.current.removeAllListeners();

        device.current?.destroy();
        device.current = null;
      }
    };
  }, [ready, dispatch, token]);

  const startDeviceCall = async ({ to, from, data }) => {
    if (activeCall && !callState.invited) {
      console.warn("Attempted to place multiple calls!");
      return;
    }

    let call;

    if (activeCall && callState.invited) {
      call = activeCall;
    } else {
      let callTo = to;

      call = await device.current.connect({
        params: { to: callTo, To: to, from, from },
        rtcConfiguration: {
          iceServers,
        },
      });

      const onAccept = (acceptedCall) => {
        console.log("[call:CallAccepted]", acceptedCall);
        setActiveCall(acceptedCall);
        addCall(acceptedCall, "accepted", data);
        updateCallStatus(acceptedCall, { type: "CONNECTED" });
      };

      const onReject = (rejectedCall) => {
        console.log("[call:CallRejected]", rejectedCall);
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
      };

      const onCancel = (rejectedCall) => {
        console.log("[call:CallCancel]", rejectedCall);
        rejectedCall.disconnect();
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
      };

      const onError = (rejectedCall) => {
        console.log("[call:CallError]", rejectedCall);
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
      };

      const onDisconnect = (disconnectedCall) => {
        console.log("[call:CallDisconnected]", disconnectedCall);

        disconnectedCall.removeAllListeners();
        disconnectedCall.disconnect();
        activeCallCleanUp(disconnectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
      };

      const onMute = () => {
        console.log("[call:CallMuted]");
      };

      call.on("mute", onMute);
      call.on("accept", onAccept);
      call.on("reject", onReject);
      call.on("cancel", onCancel);
      call.on("error", onError);
      call.on("disconnect", onDisconnect);
    }

    if (callState.invited) {
      call.accept({ rtcConfiguration: iceServers });
      // setActiveCall(call);
      // console.log(JSON.stringify(call.customParameters, null, 2));
      // dispatch({ type: "CONNECTED" });
    } else {
      setActiveCall(call);
      addCall(call, "accepted");
      updateCallStatus(call, { type: "RING" });
    }
  };

  const endDeviceCall = useCallback(() => {
    if (!device.current && !ready) {
      console.warn("Attempting to end call with no device or not ready");
    }
    if (!activeCall) {
      console.warn("Attempting to end call with no activeCall ");
    }

    if (activeCall && callState.invited && !callState.success) {
      activeCall.reject();
    } else {
      activeCall?.disconnect();
    }
  }, [ready, activeCall, callState]);

  const acceptCall = (callSid) => {
    if (!device.current && !ready) {
      console.warn("Attempting to end call with no device or not ready");
    }
    if (!callSid) {
      console.warn("Attempting to accept call with no callSid ");
    }

    if (calls[callSid]?.state === "accepted") {
      console.warn("Call is already accepted");
      return;
    }

    if (calls[callSid] && calls[callSid]?.state === "invited") {
      calls[callSid]?.call?.accept({ rtcConfiguration: iceServers });
    } else {
      console.warn(
        "Attempting to accept call with no call Invite with callSid ",
        callSid,
      );
    }
  };

  const rejectCallInvite = useCallback(
    (callSid, ignore = false) => {
      if (!device.current && !ready) {
        console.warn("Attempting to end call with no device or not ready");
      }
      if (!callSid) {
        console.warn("Attempting to reject call with no callSid ");
      }
      try {
        setCalls((calls) => {
          let newCalls = calls;
          const call = newCalls[callSid];

          if (call) {
            if (ignore) {
              call?.call?.silent();
              call.ignored = true;
            } else {
              call?.call?.reject();
            }
          } else {
            console.warn(
              "Attempting to reject call with no call Invite with callSid ",
              callSid,
            );
          }
          updateIgnoredCalls(newCalls);
          return newCalls;
        });
      } catch (e) {
        console.error(e);
      }
    },
    [ready, setCalls, updateIgnoredCalls],
  );

  const getCallData = useCallback(
    (callSid, callsArray = [], force = false) => {
      let callData = calls[callSid];
      if (force) {
        callData = callsArray[callSid];
      }
      if (!callData) return null;
      let destination = callData?.data;
      let res = {};

      if (destination?.participant?.name) {
        res.name = destination?.participant?.name;
      }

      if (!res.name && destination?.phoneNumber) {
        res.name = destination?.phoneNumber;
      }

      if (destination?.phoneNumber) {
        res.phone_number = destination?.phoneNumber;
      }

      if (destination?.phoneNumber && !!destination?.participant?.remote_id) {
        res.customer_id = destination?.participant?.remote_id;
      }

      let call = callData?.call;
      if (!res?.phone_number && call?.parameters?.From) {
        res.phone_number = call?.parameters?.From;
      }

      if (
        !res.name &&
        call?.customParameters &&
        call?.customParameters.has("participantName")
      ) {
        res.name = call?.customParameters?.get("participantName");
      }

      if (!res.name && call) {
        res.name = "Unknown Caller";
      }

      if (
        !res.customer_id &&
        call?.customParameters?.has("participantRemoteId")
      ) {
        res.customer_id = call?.customParameters?.get("participantRemoteId");
      }

      try {
        let parsedNumber = parsePhoneNumber(
          res?.phone_number,
          user?.account?.country || "US",
        );
        res = {
          ...res,
          phone_number: parsedNumber.formatNational(),
        };
      } catch (e) {}

      res.call_sid = callSid;
      res.display_name = res.name;
      delete res.name;
      return res;
    },
    [Object.values(calls), user?.account?.country],
  );

  const emitCallEvent = useCallback(
    ({ parameters, event }, callsArray = [], force = false) => {
      const call_sid = parameters?.CallSid;
      const webappPostMessageEvent = new WebAppPostMessage(CALL_EVENT_TYPE);
      webappPostMessageEvent.command = CALL_STATUS_COMMAND;
      if (event === "ended") {
        webappPostMessageEvent.data = { call_sid, status: event };
      } else {
        const callData = getCallData(call_sid, callsArray, force);
        if (!callData) return;
        else {
          webappPostMessageEvent.data = { ...callData, status: event };
        }
      }
      if (!webappPostMessageEvent.data) return;
      webappPostMessageEvent.emitEvent();
    },
    [Object.values(calls)],
  );

  useEffect(() => {
    if (!activeCall) dispatch({ type: "RESET" });
  }, [activeCall]);

  // useEffect(() => {
  //   if (ready && device) {
  //     // Now we have a live input audio track, opened with echoCancellation:true
  //     device.audio
  //       .setAudioConstraints({
  //         echoCancellation: true,
  //       })
  //       .then(
  //         () => {
  //           device.audio.setInputDevice("default");
  //         },
  //         async (err) => {
  //           // Something went wrong, most likely err is an OverconstrainedError. Let's roll back.
  //           await device.audio.unsetAudioConstraints();
  //           // We should now have a working input audio track again
  //         }
  //       );
  //   }
  // }, [ready, device]);

  return {
    ready,
    device: device.current,
    dispatch,
    activeCall,
    startDeviceCall,
    endDeviceCall,
    callState,
    token,
    callStatus,
    callInvites,
    acceptedCalls,
    acceptCall,
    rejectCallInvite,
    getCallData,
    ignoredCalls,
  };
}
