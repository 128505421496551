import { useState, useRef, useEffect } from "react";
import { getColonSeparatedHoursMinutesSeconds } from "../helpers/text";

export const useCallDuration = (callState) => {
  const [duration, setDuration] = useState(0);
  let interval = useRef().current;
  useEffect(() => {
    if (callState.success) {
      interval = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
    } else {
      setDuration((duration) => (duration > 0 ? 0 : duration));
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [callState, setDuration]);

  return {
    duration,
    callDuration: getColonSeparatedHoursMinutesSeconds(duration),
  };
};

export default useCallDuration;
