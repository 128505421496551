import { useCallback, useEffect, useState, useRef } from "react";
import { CUSTOMER_WEIGHTS_FOR_SEARCH } from "~/constants/customer";
import FieldPulse from "../services/fieldpulse";

export default function useCustomerSearch({
  search = "",
  limit = 1000,
  page = 1,
  user_id,
  filter = "all",
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  let timeout = useRef();
  let term = useRef();

  const getWeightForSearchSorting = (customer, search = "") => {
    let weight = 0;
    search = search.toLowerCase();
    const searchArray = search.split(" ");
    const { first_name = "", last_name = "", company_name = "" } = customer;
    const firstName = first_name?.toLowerCase();
    const lastName = last_name?.toLowerCase();
    const companyName = company_name?.toLowerCase();

    if (firstName?.indexOf(searchArray[0]) === 0) {
      weight +=
        firstName.length === searchArray[0].length
          ? CUSTOMER_WEIGHTS_FOR_SEARCH.FULL_SAME_FIRST_NAME
          : CUSTOMER_WEIGHTS_FOR_SEARCH.SAME_FIRST_NAME;
    }
    if (lastName?.indexOf(search) === 0) {
      weight += CUSTOMER_WEIGHTS_FOR_SEARCH.SAME_LAST_NAME;
    }
    if (companyName?.indexOf(search) === 0) {
      weight +=
        companyName.length === search.length
          ? CUSTOMER_WEIGHTS_FOR_SEARCH.FULL_SAME_COMPANY_NAME
          : CUSTOMER_WEIGHTS_FOR_SEARCH.SAME_COMPANY_NAME;
    }

    if (searchArray.length === 2) {
      if (
        firstName?.indexOf(searchArray[0]) === 0 &&
        lastName?.indexOf(searchArray[1]) === 0
      ) {
        if (
          firstName.length === searchArray[0].length &&
          lastName.length === searchArray[1].length
        ) {
          weight += CUSTOMER_WEIGHTS_FOR_SEARCH.FULL_SAME_FULL_NAME;
        } else {
          weight += CUSTOMER_WEIGHTS_FOR_SEARCH.SAME_FULL_NAME;
        }
      }
    }
    return weight;
  };

  const customDataSorter = (data, searchText) => {
    return data.sort((customerA, customerB) => {
      return (
        getWeightForSearchSorting(customerB, searchText) -
        getWeightForSearchSorting(customerA, searchText)
      );
    });
  };

  const searchCustomers = useCallback(async () => {
    setCustomers([]);
    setLoading(true);
    const res = await FieldPulse.searchContactCustomers({
      page,
      limit,
      search,
      filter: filter === "all" ? 0 : filter === "recent" ? 1 : 2,
      user_id,
    });
    if (res.error) {
      setError(res.message);
    } else {
      const sortedData = customDataSorter(res.response, search);
      setCustomers(sortedData);
    }
    setLoading(false);
  }, [
    loading,
    page,
    limit,
    search,
    setError,
    setCustomers,
    setLoading,
    filter,
  ]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (search !== term?.current?.search || filter !== term?.current?.filter) {
      timeout.current = setTimeout(() => {
        term.current = {
          search,
          filter,
        };
        searchCustomers(search);
      }, 300);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search, page, limit, filter]);

  return {
    customers,
    error,
    loading,
    search,
    limit,
    page,
    searchCustomers,
  };
}
