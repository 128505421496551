import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from "react";
import Engage from "~/services/engage";
import { sortByCreatedAt } from "~/helpers/sort";
import useTwilio from "../hooks/useTwilio";
import ThreadsContext from "./ThreadsContext";
import NumbersContext from "./NumbersContext";
import UserContext from "./UserContext";
import useNumbersSubscription from "../hooks/useNumbersSubscription";
import { CallsContext } from "./CallsContext";
import cloneDeep from "lodash.clonedeep";
import dayjs from "dayjs";
import { createNestedObject, getUrlParams } from "../helpers/text";
export const VoicemailsContext = createContext({});

export const VoicemailsProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [voicemails, setVoicemails] = useState([]);
  const [selected, setSelected] = useState();
  const { activeCall } = useTwilio();
  const { filter, selectedLines, isLineFilterCacheReady, lineSelectVisible } =
    useContext(ThreadsContext);
  const { managedNumbers, loading: loadingNumbers } =
    useContext(NumbersContext);
  const { user } = useContext(UserContext);

  const voicemailsPageRef = useRef(false);

  const getVoicemails = useCallback(async () => {
    if (loading || loadingNumbers) return;
    setLoading(true);
    let params = {
      filter: "managed",
    };

    if (filter === "all") {
      params.managed_lines = managedNumbers.map((num) => num?.id);
    } else if (filter === "assigned") {
      params.managed_lines = [user?.assigned_number?.id];
    } else {
      params.managed_lines = selectedLines || [];
    }

    if (!params?.managed_lines || !params?.managed_lines?.length) {
      setVoicemails([]);
    } else {
      const res = await Engage.getVoicemails({ page, limit: 30, ...params });

      if (res.response && !res.error) {
        if (res.response.length < 30) {
          setAllLoaded(true);
        } else {
          setAllLoaded(false);
        }
        const data = sortByCreatedAt(res.response);
        setVoicemails(page === 1 ? data : [...voicemails, ...data]);
      } else {
        setError(res);
      }
    }
    setLoading(false);
  }, [
    setLoading,
    setVoicemails,
    page,
    activeCall,
    filter,
    selectedLines,
    managedNumbers?.map((num) => num?.id),
    user?.assigned_number?.id,
    isLineFilterCacheReady,
    loadingNumbers,
  ]);

  const refreshVoicemails = async () => {
    if (page === 1) {
      getVoicemails();
    } else {
      setPage(1);
    }
  };

  const prevPage = useCallback(() => {
    if (page === 1 || loading) return;
    setPage(page - 1);
  }, [loading]);

  const nextPage = useCallback(() => {
    if (loading || allLoaded) return;
    setPage((page) => page + 1);
  }, [loading, allLoaded]);

  useEffect(() => {
    if (
      isLineFilterCacheReady &&
      filter &&
      !loadingNumbers &&
      voicemailsPageRef?.current &&
      !lineSelectVisible
    ) {
      getVoicemails();
    }
  }, [
    page,
    filter,
    selectedLines,
    isLineFilterCacheReady,
    loadingNumbers,
    voicemailsPageRef?.current,
    lineSelectVisible,
    user?.assigned_number?.id,
  ]);

  const initialLoading = useMemo(() => loading && page === 1);

  const deleteVoicemail = async (id) => {
    if (!id) return;
    await Engage.deleteVoicemail(id);
    await refreshVoicemails();
  };

  const setPageActive = (active) => {
    voicemailsPageRef.current = active;
  };

  const shouldAddVoicemailToList = useCallback(
    (twilio_number_id) => {
      let managed_lines = [];
      if (!loadingNumbers) {
        if (filter === "all") {
          managed_lines = managedNumbers?.map((num) => num?.id);
        } else if (filter === "assigned") {
          managed_lines = [user?.assigned_number?.id];
        } else {
          managed_lines = selectedLines;
        }
        if (managed_lines.includes(twilio_number_id)) {
          return true;
        }
      }
      return false;
    },
    [
      filter,
      selectedLines,
      user?.assigned_number?.id,
      managedNumbers?.map((num) => num.id),
      loadingNumbers,
    ],
  );

  const updateVoicemail = useCallback(
    (voicemail) => {
      try {
        setVoicemails((voicemails) => {
          const existingVoicemail = voicemails?.find(
            (v) => v?.id === voicemail?.id,
          );
          if (existingVoicemail) {
            let newVoicemails = cloneDeep(voicemails);
            let oldVoicemail = newVoicemails.find(
              (v) => v?.id === voicemail?.id,
            );
            oldVoicemail = { ...oldVoicemail, ...voicemail };
            setSelected((selectedVoicemail) => {
              if (selectedVoicemail?.id === voicemail?.id) {
                return { ...selectedVoicemail, ...voicemail };
              } else return selectedVoicemail;
            });
            return newVoicemails;
          } else {
            const shouldAppendVoicemail = shouldAddVoicemailToList(
              voicemail?.call_data?.twilio_number?.id,
            );
            if (voicemails.length) {
              const lastVoicemail = voicemails[voicemails.length - 1];
              if (
                dayjs(voicemail?.created_at).isBefore(
                  dayjs(lastVoicemail?.created_at),
                )
              )
                return voicemails;
              else {
                const newVoicemails = sortByCreatedAt([
                  voicemail,
                  ...voicemails,
                ]);
                if (shouldAppendVoicemail) return newVoicemails;
                return voicemails;
              }
            } else {
              return [voicemail];
            }
          }
        });
      } catch {}
    },
    [setSelected, setVoicemails, loading, shouldAddVoicemailToList],
  );

  const pusherVoicemailUpdateHandler = useCallback(
    (data) => {
      try {
        if (data && !loadingNumbers) {
          const parsedData = getUrlParams(`?${data}`, true);
          let params = {};
          Object.keys(parsedData).map((key) => {
            const splitData = key.split(".");
            const value = parsedData[key];
            createNestedObject(params, splitData, value);
          });

          const twilio_number = managedNumbers?.find(
            (m) => m.id === params.twilio_number_id,
          );

          params = {
            ...params,
            ...params?.voicemail_data,
            read: params.read === "true",
            duration: parseInt(params?.voicemail_data?.duration || "0"),
          };

          params.call_data = {
            ...params?.call_data,
            conversation_id: params?.conversation_id,
            missed: true,
            outbound_missed: false,
            twilio_number,
            duration: parseInt(params?.call_data?.duration || "0"),
          };

          params.participant = {
            ...params?.participant,
            valid: params.participant.valid === "true",
            is_child: params.participant.is_child === "true",
          };

          delete params?.conversation_id;
          delete params?.voicemail_data;
          delete params?.twilio_number_id;
          updateVoicemail(params);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [updateVoicemail, loadingNumbers, managedNumbers],
  );

  useNumbersSubscription("voicemail-created", (payload) => {
    pusherVoicemailUpdateHandler(payload);
  });

  return (
    <VoicemailsContext.Provider
      value={{
        error,
        loading,
        voicemails,
        getVoicemails,
        refreshVoicemails,
        nextPage,
        prevPage,
        initialLoading,
        deleteVoicemail,
        selected,
        setSelected,
        setPageActive,
      }}
    >
      {children}
    </VoicemailsContext.Provider>
  );
};
