import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  APP_ENV,
  MAIN_BACKEND_BASE_URI,
} from "../../../constants/app/index.web";
import { REPORTING_URL } from "../../../constants/reporting";
import {
  CALL_DIRECTION,
  CALL_OUTCOME,
  OUTBOUND_MISSED_CALL_KEY,
} from "../../../constants/calls";
import dayjs from "dayjs";
import SolidButton from "../../../components/Buttons/SolidButton";
import TeamContext from "../../../contexts/TeamContext";
import { CallsReportingContext } from "../../../contexts/CallsReportingContext";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import Row from "../../../layouts/Row";
import { Field } from "../../../components/PhoneTree/PhoneTreeForm/index.web";
import palette from "../../../styles/palette";
import DatePicker from "../../../components/DatePicker/index.web";
import FieldPulse from "../../../services/fieldpulse";
import UserContext from "../../../contexts/UserContext";
import Heading from "../../../components/Heading/index.web";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { Strings } from "../../../constants/strings";
import Col from "../../../layouts/Col";
import commonStyles from "../../../styles/common";

const ReportingScreen = () => {
  const {
    getCallsReporting,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    loadMoreCalls,
    loadingMoreCalls,
    calls,
    total,
  } = useContext(CallsReportingContext);
  const { fpUser, webappToken, user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const {
    members,
    getMembers,
    loading: loadingTeamMembers,
  } = useContext(TeamContext);

  const iframeRef = useRef();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    getMembers();
  }, []);

  const teamMembers = useMemo(() => {
    let res = {};
    if (members?.length)
      members
        ?.filter((m) => m?.is_active)
        ?.map((teamMember) => {
          res[teamMember?.id] = teamMember;
        });
    return res;
  }, [members]);

  const filteredData = useCallback(
    (data) => {
      if (loading || loadingTeamMembers) return [];
      let res = [];

      data?.forEach((call) => {
        let data = {};

        const { id = "" } = call || {};

        const callData = call?.attributes || {};

        const {
          created_at = "",
          note = "",
          voicemail = "",
          call_recording = "",
          duration = 0,
          conversation_id = "",
        } = callData;

        data = {
          ...data,
          id,
          created_at,
          notes: note,
          voicemail,
          call_recording,
          duration,
          conversation_id,
        };
        const customer = callData?.author;
        const {
          id: customer_id,
          name = "",
          remote_id,
          first_name = "",
          last_name = "",
        } = customer;

        const { to, from, direction } = callData;
        let phone = to;

        if (direction === "inbound") {
          phone = from;
        }

        data.customer = {
          id: customer_id,
          name,
          first_name,
          last_name,
          existing: !!remote_id,
          phone: phone,
        };

        data.phone_line = {
          friendly_name: callData?.twilio_number?.friendly_name,
          phone_number: callData?.twilio_number?.phone_number,
        };

        data.lead_source = {
          name: callData?.twilio_number?.lead_source_name,
          id: callData?.twilio_number?.lead_source_id,
        };

        data.direction =
          callData?.direction === "outbound-dial" ? "outgoing" : "incoming";

        data.direction = CALL_DIRECTION[data.direction];

        if (callData?.missed) {
          data.outcome = CALL_OUTCOME.missed;
          data.called_by = null;
        } else {
          data.outcome = callData?.[OUTBOUND_MISSED_CALL_KEY]
            ? CALL_OUTCOME.unanswered
            : CALL_OUTCOME.answered;
          try {
            data.called_by = teamMembers[callData?.called_by?.remote_id];
          } catch (e) {
            data.called_by = callData?.called_by;
          }
        }

        res.push(data);
      });

      return res;
    },
    [loading, teamMembers, loadingTeamMembers, user?.account?.country],
  );

  const onDateChangeHandler = useCallback(
    (prop) => (value) => {
      if (prop === "to") {
        let newDate = value;
        if (
          dayjs(value).isBefore(dayjs(startDate), "day") ||
          dayjs(value).isSame(dayjs(startDate), "day")
        ) {
          newDate = new Date(startDate);
          newDate.setDate(newDate.getDate() + 1);
          diff = true;
        }
        if (dayjs(newDate).isValid()) setEndDate(() => newDate);
      } else if (prop === "from") {
        let newDate = value;
        if (
          dayjs(value).isAfter(dayjs(endDate), "day") ||
          dayjs(value).isSame(dayjs(endDate), "day")
        ) {
          newDate = new Date(endDate);
          newDate.setDate(newDate.getDate() - 1);
          diff = true;
        }

        if (dayjs(newDate).isValid()) setStartDate(() => newDate);
      }
    },
    [startDate, endDate],
  );

  const sendEvent = useCallback(
    (data) => {
      const iframe = document.querySelector(".reporting-iframe");

      iframe.contentWindow.postMessage(
        {
          command: {
            commandType: initialized ? "update" : "init",
            payload: data,
            customFields: [],
            reportingType: "engage",
            apiUrl: MAIN_BACKEND_BASE_URI,
            webappToken: FieldPulse._token || webappToken,
            companyInfo: {
              currencySign: "",
              estimateName: "",
              serviceName: "",
              tax: "",
              isPricebookHviEnabled: false,
              companyId: fpUser?.company_id,
            },
          },
        },
        REPORTING_URL[APP_ENV],
      );
    },
    [initialized, fpUser?.company_id, !!FieldPulse._token, webappToken],
  );

  const runReport = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getCallsReporting();
      if (!res.error) {
        setInitialized(() => true);
        const data = filteredData(res?.data);
        sendEvent(data);
      }
    } catch (e) {
      console.warn(e);
    }
    setLoading(false);
  }, [sendEvent, filteredData]);

  const addMoreData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await loadMoreCalls();
      if (!res.error) {
        setInitialized(() => true);
        const data = filteredData(res?.data);
        sendEvent(data);
      }
    } catch (e) {
      console.warn(e);
    }
    setLoading(false);
  }, [sendEvent, filteredData]);

  return (
    <ScrollView
      style={{
        flex: 1,
      }}
    >
      <Row
        pl={16}
        pr={16}
        style={{
          height: 65,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Col leftCenter>
          <Row leftCenter style={commonStyles.alignCenter}>
            <Heading size={5} pb={8} mb={0}>
              Reporting
            </Heading>
            <InfoIconButton link={Strings.REPORTING_DOCS_URL} />
          </Row>
        </Col>
      </Row>
      <Row
        leftCenter
        style={{
          gap: 16,
          alignItems: "flex-end",
          marginLeft: 16,
        }}
      >
        <Text style={styles.field_label}>Call Date Range:</Text>
        <Field label="From">
          <DatePicker
            value={startDate}
            onChange={onDateChangeHandler("from")}
            disabled={loading}
          />
        </Field>
        <Field label="To">
          <DatePicker
            value={endDate}
            onChange={onDateChangeHandler("to")}
            min={startDate}
            disabled={loading}
          />
        </Field>
        <View style={{ marginTop: "auto", marginBottom: 4 }}>
          <SolidButton
            label="Run Report"
            style={styles.button}
            color={palette.blue_turquoise}
            onPress={runReport}
            loading={loading}
            disabled={loadingTeamMembers}
          />
        </View>
        {calls?.length <= total && calls?.length > 0 && (
          <View>
            <Row>
              <Text style={[styles.field_label, { marginBottom: 4 }]}>
                Calls Fetched: {calls?.length} / {total}
              </Text>
            </Row>
            <Row>
              <View style={{ marginBottom: 4 }}>
                <SolidButton
                  label="Load More"
                  style={styles.button}
                  color={palette.primary}
                  onPress={addMoreData}
                  loading={loadingMoreCalls}
                  disabled={calls?.length === total}
                />
              </View>
            </Row>
          </View>
        )}
      </Row>
      <Row
        style={{
          flex: 1,
        }}
        padding={16}
        margin={16}
      >
        <iframe
          title="Reporting"
          width="100%"
          className="reporting-iframe"
          height={750}
          src={REPORTING_URL[APP_ENV]}
          onLoad={(data) => {}}
          style={{
            margin: 16,
            border: `1px solid ${palette.light_grey}`,
            borderColor: palette.light_grey,
            borderWidth: StyleSheet.hairlineWidth,
          }}
        />
      </Row>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  field_label: {
    fontSize: 14,
    fontWeight: "500",
    color: palette.dark,
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 16,
  },
  input: {
    backgroundColor: palette.lightest_grey,
    borderColor: palette.light_grey,
    borderBottomWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
  },
  button: {
    height: 45,
    borderRadius: 0,
    paddingHorizontal: 24,
    minWidth: 130,
  },
});

export default ReportingScreen;
