import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useReducer,
  useContext,
} from "react";
import { Text, View } from "react-native";
import { AsYouType } from "libphonenumber-js";
// import { useNavigation }       from '@react-navigation/native';

import Col from "~/layouts/Col";
import Row from "~/layouts/Row";

import SolidButton from "~/components/Buttons/SolidButton/";
import IconButton from "~/components/Buttons/IconButton";
import Heading from "~/components/Heading/index.web";
import useTwilio from "~/hooks/useTwilio";

import Engage from "~/services/engage";
import palette from "~/styles/palette";
import StyledInput from "../../StyledInput/index";
import NumbersContext from "../../../contexts/NumbersContext";
import {
  faAsterisk,
  faChevronLeft,
  faHashtag,
  faLoader,
  faPhone,
  faPlus,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { safeParseNumber } from "../../../helpers/phoneNumbers";

const initialState = {
  number: "",
  participant: null,
};

const dialReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_NUM": {
      return {
        ...state,
        number: `${state.number}${payload}`,
      };
    }

    case "SET_NUM": {
      return {
        ...state,
        number: payload,
      };
    }

    case "REMOVE_NUM": {
      return {
        ...state,
        number: state.number.slice(0, -1),
      };
    }

    case "CLEAR_NUM": {
      return {
        ...state,
        number: "",
      };
    }

    case "FOUND_PARTICIPANT": {
      return {
        ...state,
        participant: payload,
      };
    }

    case "CLEAR_PARTICIPANT": {
      return {
        ...state,
        participant: null,
      };
    }

    default: {
      return state;
    }
  }
};

const Dialer = () => {
  const { setCallQueue, endCall, activeCall } = useTwilio();

  const [dialState, dialDispatch] = useReducer(dialReducer, initialState);
  const { activeNumber } = useContext(NumbersContext);

  const [loading, setLoading] = useState(false);
  const onCallVoip = useCallback(() => {
    if (!activeCall) {
      setCallQueue({ to: dialState.number, metaData: dialState.participant });

      // navigation.navigate("Modal", {
      //   screen: "ActiveCall",
      // });
    } else {
      endCall();
    }
  }, [activeCall, dialState]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (dialState.number.length > 9) {
        setLoading(true);
        const res = await Engage.getParticipantByPhone({
          phone: dialState.number,
        });
        dialDispatch({ type: "FOUND_PARTICIPANT", payload: res.response });
        setLoading(false);
      }
    }, 800);

    return () => {
      clearTimeout(timeout);
      dialDispatch({ type: "CLEAR_PARTICIPANT" });
    };
  }, [dialState.number]);

  const inputValue = useMemo(() => {
    const value = new AsYouType({ defaultCallingCode: "1" }).input(
      dialState.number,
    );
    if (dialState.number.startsWith("+")) {
      const parsedNumber = safeParseNumber(dialState.number, "US");

      if (parsedNumber) {
        return parsedNumber.formatInternational();
      } else {
        return value;
      }
    } else {
      return value;
    }
  }, [dialState.number]);

  const numberButtons = useMemo(() => {
    const numbers = new Array(9).fill(0);

    const onPress = (number) => () => {
      if (dialState?.number?.length < 15)
        dialDispatch({ type: "ADD_NUM", payload: number });
    };

    return numbers.map((_, i) => {
      return (
        <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80} key={i}>
          <SolidButton
            label={`${i + 1}`}
            color={palette.light_grey}
            border
            onPress={onPress(i + 1)}
            width={80}
            labelStyle={{ fontSize: 32 }}
            style={{ height: 80, borderRadius: 80 }}
          />
        </Col>
      );
    });
  }, [dialDispatch, dialState?.number?.length]);

  const numberRows = useMemo(() => {
    return (
      <Row pl={32} pr={32} centered>
        <Col centered>
          <Row center>{numberButtons.slice(0, 3)}</Row>

          <Row center>{numberButtons.slice(3, 6)}</Row>

          <Row center>{numberButtons.slice(6, 9)}</Row>

          <Row center>
            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              {activeCall ? (
                <IconButton
                  faPro
                  faProIcon={faAsterisk}
                  iconSize={28}
                  onPress={() =>
                    dialDispatch({ type: "ADD_NUM", payload: "*" })
                  }
                  color={palette.light_grey}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              ) : (
                <IconButton
                  faPro
                  faProIcon={faXmark}
                  iconSize={32}
                  onPress={() => dialDispatch({ type: "CLEAR_NUM" })}
                  color={palette.light_grey}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              )}
            </Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              <SolidButton
                label="0"
                color={palette.light_grey}
                onPress={() => dialDispatch({ type: "ADD_NUM", payload: 0 })}
                width={80}
                labelStyle={{ fontSize: 32 }}
                style={{ height: 80, borderRadius: 80 }}
              />
            </Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              {activeCall ? (
                <IconButton
                  onPress={() =>
                    dialDispatch({ type: "ADD_NUM", payload: "#" })
                  }
                  faPro
                  faProIcon={faHashtag}
                  iconSize={28}
                  color={palette.light_grey}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              ) : dialState.number.length > 0 ? (
                <IconButton
                  onPress={() => dialDispatch({ type: "REMOVE_NUM" })}
                  faPro
                  faProIcon={faChevronLeft}
                  iconSize={32}
                  color={palette.light_grey}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              ) : (
                <IconButton
                  onPress={() =>
                    dialDispatch({ type: "ADD_NUM", payload: "+" })
                  }
                  faPro
                  faProIcon={faPlus}
                  iconSize={32}
                  color={palette.light_grey}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              )}
            </Col>
          </Row>

          <Row center mb={32}>
            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}></Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              <IconButton
                faPro
                faProIcon={loading ? faLoader : faPhone}
                iconSize={32}
                color={activeCall ? palette.danger : palette.success}
                onPress={onCallVoip}
                loading
                disabled={
                  !activeNumber ||
                  (!activeCall && dialState.number.length < 10) ||
                  loading
                }
                width={80}
                mr={0}
                ml={0}
                style={[
                  { height: 80 },
                  !!activeCall && {
                    transform: [
                      {
                        rotateZ: "135deg",
                      },
                    ],
                  },
                ]}
              />
            </Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}></Col>
          </Row>
        </Col>
      </Row>
    );
  }, [numberButtons, dialState, activeCall, onCallVoip, loading]);

  return (
    <Row mt={32} style={{ maxHeight: "70%" }}>
      <Col pt={32}>
        <View>
          <StyledInput
            autoFocus
            style={{ textAlign: "center", fontWeight: "500" }}
            placeholder="Enter a phone number"
            onChangeText={(val) => {
              const value = new AsYouType({ defaultCallingCode: "1" }).input(
                dialState.number,
              );
              if (val?.startsWith("1")) {
                val = `+${val}`;
              }
              if (
                val.length < value.length &&
                value[value.length - 1] === ")"
              ) {
                val = val.slice(0, -1);
              }

              dialDispatch({
                type: "SET_NUM",
                payload: val.replace(/[^0-9+()]/g, ""),
              });
            }}
            value={inputValue}
            maxLength={15}
            nativeID="dialer_input"
          />
          <View style={{ height: 60, paddingVertical: 16 }}>
            {dialState.participant && dialState.participant.name ? (
              <Heading size={4} center>
                {dialState.participant.name}
              </Heading>
            ) : null}
          </View>
        </View>

        <Row center mt={16}>
          <Col>{numberRows}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default function DialerPanel() {
  return (
    <View style={{ height: "100%" }}>
      <Dialer />
    </View>
  );
}
