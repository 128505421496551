export const Strings = {
  CALL_FORWARDING_LABEL: "Call Forwarding",
  PHONE_TREE_LABEL: "Phone Tree",
  SEQUENTIAL_CALL_LABEL: "Sequential Call Ring",
  SEQUENTIAL_CALL_MISCONFIGURED_NUMBER:
    "This phone line is not available for Sequential Call Ring, please remove this.",
  A2P_10DLC_SUBMISSION_MESSAGE: `We'll verify your new number for Engage within two weeks. After approval, you can start sending messages.\nCheck here or contact support for updates.`,
  FLICENT_CAMPAIGN_WARNING_MESSAGE:
    "To unlock the full potential of Engage and gain access to all features, please resubmit your 10DLC campaign with your business details. Your initial submission included FieldPulse business details, which needs to be updated.",
  RESUBMIT_CAMPAIGN_LABEL: "Resubmit Campaign",
  FLICENT_CAMPAIGN_CALLER_ID_MESSAGE:
    "Invalid 10 DLC information. Please update before gaining access to the Caller ID feature. ",
  CALLER_ID_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9390426-caller-id",
  WORKING_HOURS_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077040-engage-working-hours",
  CALL_ROUTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077045-call-routing",
  VOICEMAIL_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077046-engage-voicemails",
  A2P_10DLC_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077053-10dlc-information",
  REPORTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9829548-engage-reporting",
};
