import React, { useContext, useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Switch, Route, Link } from "react-router-dom";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import ConversationList from "~/components/ConversationList/index.web";
import Screen from "~/layouts/Screen/";
import ParticipantDetails from "../../components/Participant/ParticipantDetails/index.web";
import ConversationHeader from "../../components/Headers/ConversationHeader/index.web";
import ConversationScreen from "../ConversationScreen/index.web";
import ComposeScreen from "../ComposeScreen/index.web";
import UserContext from "../../contexts/UserContext";
import NumbersContext from "../../contexts/NumbersContext";
import palette from "../../styles/palette";
import ThreadsContext from "../../contexts/ThreadsContext";
import useTwilio from "../../hooks/useTwilio";
import Heading from "../../components/Heading/index.web";
import IconButton from "../../components/Buttons/IconButton";
import ConversationContext from "../../contexts/ConversationContext";
import MenuBar from "../../components/MenuBar/index.web";

export default function InboxScreen({ history, match }) {
  const { user } = useContext(UserContext);

  const { activeNumber } = useContext(NumbersContext);
  const { refreshThreads } = useContext(ThreadsContext);
  const { activeCall, startCall } = useTwilio();
  const onCallPress = () => {
    startCall(value?.phone_e164, value);
  };

  const { conversation, getConversation } = useContext(ConversationContext);

  const { participant, customer } = conversation;

  const value = useMemo(() => {
    let value = {
      ...participant,
      ...customer,
    };

    if (participant?.phone_number && !value.phone) {
      value.phone = participant.phone_number;
    }

    return value;
  }, [participant, customer]);

  return (
    <Screen>
      <Row style={[styles.inner]}>
        <Col>
          <Row style={{ flex: 1 }}>
            <Col size={3} style={[styles.leftPanel]}>
              <MenuBar />
              <ConversationList />
            </Col>
            <Col style={[styles.centerPanel]}>
              {activeNumber ? (
                <Switch>
                  <Route path="/messages/new" component={ComposeScreen} />
                  <Route path="/messages/:id" component={ConversationScreen} />
                  <Route path="/messages"></Route>
                </Switch>
              ) : (
                <Col center>
                  <View style={[styles.greeting_container]}>
                    {user?.first_name ? (
                      <Text style={[styles.greeting]}>
                        Hi, {user.first_name}!
                      </Text>
                    ) : (
                      <Text style={[styles.greeting]}>Hi!</Text>
                    )}
                    <Text style={[styles.text]}>
                      You don't have an Active Number.
                    </Text>
                    <Link
                      to="/settings/number"
                      style={{ textDecoration: "none" }}
                    >
                      <Text style={[styles.link]}>Manage Numbers</Text>
                    </Link>
                  </View>
                </Col>
              )}
            </Col>
            <Switch>
              <Route path="/messages/new">
                {/* <Col style={[styles.contactPanel]}>
                  <SuggestedCustomersList />
                </Col> */}
              </Route>
              <Route path="/messages/:id">
                <Col style={[styles.participantPanel]}>
                  <View
                    style={{
                      backgroundColor: "white",
                      height: 75,
                      borderColor: palette.light_grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Col leftCenter pl={16}>
                      <Row pt={8} center>
                        <Heading
                          size={3}
                          color={palette.label_grey}
                          mt={0}
                          mb={0}
                        >
                          Customer Profile
                        </Heading>
                        <Col right>
                          <Row right>
                            <IconButton
                              iconName="phone"
                              mt={0}
                              mb={0}
                              style={{
                                marginTop: 0,
                                marginBottom: 4,
                                marginRight: 16,
                                borderRadius: 4,
                              }}
                              border
                              round={false}
                              mr={0}
                              iconColor={palette.green}
                              color={palette.light_green}
                              borderColor={palette.green}
                              disabled={
                                !activeNumber || activeCall || !value.phone_e164
                              }
                              onPress={onCallPress}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </View>
                  <ParticipantDetails
                    onUpdate={async () => {
                      await getConversation();
                      await refreshThreads();
                    }}
                  />
                  <Route path="/messages"></Route>
                </Col>
              </Route>
            </Switch>
          </Row>
        </Col>
      </Row>
    </Screen>
  );
}

const styles = StyleSheet.create({
  conversation__container: {
    backgroundColor: "#fff",
    flex: 1,
  },
  leftPanel: {
    borderRightWidth: 1,
    borderRightColor: "#eeeddd",
  },
  leftPanelCompact: {
    maxWidth: "100%",
    height: "100%",
    borderRightWidth: 1,
    borderRightColor: "#eeeddd",
  },
  centerPanel: {
    minWidth: 380,
  },
  participantPanel: {
    maxWidth: "25%",
    minWidth: 375,
    borderLeftWidth: 1,
    borderLeftColor: "#eeeddd",
  },
  contactPanel: {
    maxWidth: "18%",
    minWidth: 325,
    borderLeftWidth: 1,
    borderLeftColor: "#eeeddd",
  },
  inner: {
    flex: 1,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
    textAlign: "center",
  },
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  greeting_container: {
    alignItems: "center",
  },
});
