import React, { useCallback, useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native-web";
import Screen from "~/layouts/Screen/";
import Row from "~/layouts/Row";
import CustomersActionBar from "../../components/Bars/CustomersActionBar/index.web";
import Col from "../../layouts/Col";
import CustomersHeader from "../../components/Headers/CustomersHeader/index.web";
import palette from "~/styles/palette";
import CustomersList from "../../components/CustomersList/index.web";
import ParticipantDetails from "~/components/Participant/ParticipantDetails/index.web";
import ConversationContext from "~/contexts/ConversationContext";
import IconButton from "~/components/Buttons/IconButton";
import CustomersContext from "../../contexts/CustomersContext";
import UserContext from "../../contexts/UserContext";
import Heading from "../../components/Heading/index.web";
import useTwilio from "../../hooks/useTwilio";
import NumbersContext from "../../contexts/NumbersContext";
import MenuBar from "../../components/MenuBar/index.web";

export default function CustomersScreen({ history }) {
  const [activeCustomer, setActiveCustomer] = useState(null);
  const { fpUser, canSeeCustomerContact } = useContext(UserContext);
  const { refreshCustomers } = useContext(CustomersContext);
  const { startCall, activeCall } = useTwilio();
  const { activeNumber } = useContext(NumbersContext);
  const {
    participant,
    setParticipantFromCustomer,
    setParticipant,
    conversation,
    getConversationByParticipant,
  } = useContext(ConversationContext);
  const [didPressText, setDidPressText] = useState(false);

  const onTextPress = useCallback(async () => {
    if (participant?.id) {
      await setParticipant(participant);
    } else {
      await setParticipantFromCustomer(activeCustomer);
    }
    await getConversationByParticipant({
      id: participant?.id,
      remote_id: activeCustomer.id,
    });
    setDidPressText(true);
  }, [participant, setParticipant, setParticipantFromCustomer, activeCustomer]);

  const onCallPress = () => {
    startCall(activeCustomer.phone_e164, activeCustomer);
  };

  useEffect(() => {
    if (didPressText) {
      setDidPressText(false);
      if (conversation?.id) history.push(`/messages/${conversation?.id}`);
      else history.push("/messages/new");
    }
  }, [didPressText, conversation]);

  useEffect(() => {
    if (!fpUser.can_view_customer_list) history.replace("/messages");
  }, [history?.location?.pathname, fpUser]);

  return (
    <Screen>
      <Row style={[styles.inner]}>
        <Col size={3}>
          <MenuBar />
          <CustomersList
            onPress={(customer) => {
              setActiveCustomer(customer?.id);
              if (history.location.hash === "#edit-customer") {
                history.replace("/customers");
              }
            }}
            showContact={canSeeCustomerContact}
            active={activeCustomer}
          />
        </Col>
        <Col></Col>
        {activeCustomer && (
          <Col size={3} style={[styles.rightPanel]}>
            <View
              style={{
                backgroundColor: "white",
                height: 76,
                borderColor: palette.light_grey,
                borderBottomWidth: 1,
              }}
            >
              <Col leftCenter pl={16}>
                <Row pt={8} center>
                  <Heading size={3} color={palette.label_grey} mt={0} mb={0}>
                    Customer Profile
                  </Heading>
                  <Col right>
                    <Row right>
                      <IconButton
                        iconName="phone"
                        mt={0}
                        mb={0}
                        style={{
                          marginTop: 0,
                          marginBottom: 4,
                          borderRadius: 4,
                        }}
                        border
                        round={false}
                        mr={0}
                        iconColor={palette.green}
                        color={palette.light_green}
                        borderColor={palette.green}
                        disabled={
                          !activeNumber ||
                          activeCall ||
                          !activeCustomer.phone_e164
                        }
                        onPress={onCallPress}
                      />
                      <IconButton
                        iconName="message-circle"
                        mt={0}
                        mb={0}
                        style={{
                          marginTop: 0,
                          marginBottom: 4,
                          borderRadius: 4,
                        }}
                        border
                        round={false}
                        iconColor={palette.primary}
                        color={palette.light_blue}
                        borderColor={palette.primary}
                        disabled={!activeNumber || !activeCustomer.phone_e164}
                        onPress={onTextPress}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </View>
            <ParticipantDetails
              customer={activeCustomer}
              onUpdate={refreshCustomers}
            />
          </Col>
        )}
      </Row>
    </Screen>
  );
}

const styles = StyleSheet.create({
  inner: {
    flex: 1,
    backgroundColor: palette.lightest_grey,
  },
  rightPanel: {
    borderLeftWidth: 1,
    borderLeftColor: "#eeeddd",
  },
});
