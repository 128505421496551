import React, { useCallback, useState, useEffect } from "react";
import { Dimensions, StyleSheet, View, Text, ScrollView } from "react-native";
import Row from "../layouts/Row";
import palette from "../styles/palette";
import Col from "../layouts/Col";
import { useHistory } from "react-router-dom";
import IconButton from "../components/Buttons/IconButton";
import SolidButton from "../components/Buttons/SolidButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

export default function Modal({
  title,
  confirmButtonProps,
  fixed = false,
  overlayOpacity = null,
  children,
}) {
  const isFullScreen = Dimensions.get("window").width < 960;
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const onClose = useCallback(() => {
    const { pathname, state } = history.location;
    setVisible(false);
    setTimeout(() => {
      history.replace(pathname, state);
    }, 300);
  }, [setVisible, history]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, [200]);
  }, []);

  return (
    <View
      style={[
        styles.modal_overlay,
        overlayOpacity !== null && {
          backgroundColor: `rgba(45, 62, 80, ${overlayOpacity})`,
        },
        fixed && { position: "fixed" },
      ]}
    >
      <View
        style={[
          styles.modal_container,
          { width: isFullScreen ? "100%" : 600 },
          { right: visible ? 0 : -600 },
        ]}
      >
        <ModalHeader title={title} onClose={onClose} />
        <ScrollView style={[styles.modal__body]}>{children}</ScrollView>
        {confirmButtonProps && (
          <View style={[styles.modal__footer]}>
            <SolidButton {...confirmButtonProps} />
          </View>
        )}
      </View>
    </View>
  );
}

const ModalHeader = ({ title, onClose }) => {
  return (
    <View style={[styles.modal__header]}>
      <Row pl={32} pr={8}>
        <Col leftCenter>
          <Text style={[styles.modal__header_title_text]}>{title}</Text>
        </Col>
        <Col rightCenter>
          <IconButton
            onPress={onClose}
            faPro
            faProIcon={faXmark}
            iconSize={28}
            color={palette.primary}
            iconColor={palette.white}
          />
        </Col>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 75,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    paddingBottom: 8,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "100%",
    height: "100%",
    top: 0,
    right: 0,
    backgroundColor: palette.white,
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    transitionProperty: "right",
    transitionDelay: "100ms",
    transitionDuration: "200ms",
  },
});
