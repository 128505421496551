import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { View, StyleSheet, FlatList, Text } from "react-native";

import { CallsContext } from "../../contexts/CallsContext";

import IconButton from "~/components/Buttons/IconButton/index";
import palette from "../../styles/palette";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import { getSmartTimestamp } from "../../helpers/day";
import useTwilio from "~/hooks/useTwilio";
import { TouchableWithoutFeedback } from "react-native-web";
import {
  faPhoneArrowUpRight,
  faPhoneMissed,
  faPhoneArrowDownLeft,
  faVolume,
  faVoicemail,
  faPhone,
  faComments,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import ConversationContext from "../../contexts/ConversationContext";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import LineFilterBar from "../Bars/LineFilterBar/index.web";
import ThreadsContext from "../../contexts/ThreadsContext";
import useCallLogssearch from "../../hooks/useCallLogSearch";
import LoadingIndicator from "../LoadingIndicatior/index.web";
import SearchBar from "../Bars/SearchBar/index.web";
import { SEARCH_LIMIT } from "../../constants/search";
import { OUTBOUND_MISSED_CALL_KEY } from "../../constants/calls";
import { getCommaSeparatedHoursMinutesSeconds } from "../../helpers/text";
import commonStyles from "../../styles/common";

const CallLogList = ({ style = {} }) => {
  const { loading, calls, nextPage, initialLoading, setPageActive } =
    useContext(CallsContext);

  const { loading: loadingNumbers } = useContext(NumbersContext);

  const [refreshing, setRefreshing] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const showSearchResults = searchTerm.length >= SEARCH_LIMIT;
  const { managedNumbers } = useContext(NumbersContext);
  const { user, canSeeCustomerContact } = useContext(UserContext);
  const { filter, selectedLines } = useContext(ThreadsContext);

  let params = {};

  if (filter === "all") {
    params.managed_lines = managedNumbers.map((num) => num?.id);
  } else if (filter === "assigned") {
    params.managed_lines = [user?.assigned_number?.id];
  } else {
    params.managed_lines = selectedLines || [];
  }

  const { callLogs: suggestions, loading: loadingSuggestions } =
    useCallLogssearch({
      search: searchTerm,
      page: 1,
      limit: 50,
      filter,
      ...params,
    });

  useEffect(() => {
    const refreshCallLogList = async () => {
      setRefreshing(true);
      setPageActive(true);
      setRefreshing(false);
    };
    refreshCallLogList();

    return () => {
      setPageActive(false);
    };
  }, []);

  const renderCallListItem = useCallback(
    (props) => {
      return <CallListItem {...{ ...props, canSeeCustomerContact }} />;
    },
    [canSeeCustomerContact],
  );

  return (
    <View style={[styles.container, style]}>
      <View
        style={{
          paddingHorizontal: 6,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: palette.light_grey,
          zIndex: 200,
        }}
      >
        <CallLogFilter
          managedNumbersLength={managedNumbers?.length}
          loadingCalls={loading}
        />
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={"Search by name or phone"}
          managedNumbersLength={managedNumbers?.length}
        />
      </View>
      {(loadingSuggestions && searchTerm?.length > 0) ||
      initialLoading ||
      refreshing ||
      loadingNumbers ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <LoadingIndicator title={"Loading Calls"} />
        </View>
      ) : (
        <FlatList
          data={showSearchResults ? suggestions : calls}
          renderItem={renderCallListItem}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => `call-list-item-${item.id}`}
          onEndReachedThreshold={0.9}
          onEndReached={nextPage}
          ListEmptyComponent={NoCallsComponent}
        />
      )}
    </View>
  );
};

const CallLogFilter = ({ managedNumbersLength = 0, loadingCalls = false }) => {
  if (managedNumbersLength > 1) return <LineFilterBar loading={loadingCalls} />;

  return null;
};

const CallListItem = (props) => {
  const { item, canSeeCustomerContact } = props || {};

  const { selected, setSelected } = useContext(CallsContext);
  const { conversation, setConversationById, setTempHeader } =
    useContext(ConversationContext);
  const { activeNumber } = useContext(NumbersContext);
  const { activeCall, setCallQueue } = useTwilio();

  const isCustomerSaved = item?.author?.remote_id !== null;
  const hasParent = !!item?.author?.parent;
  const parentName = item?.author?.parent?.name;

  const phoneNumber = useMemo(() => {
    const { to, from, direction } = item;
    let phone = to;

    if (direction === "inbound") {
      phone = from;
    }

    return phone;
  }, [item]);

  const onCallPress = () => {
    setCallQueue({ to: phoneNumber, metaData: item.author });
  };

  const iconProps = useMemo(() => {
    if (item.missed) {
      return {
        icon: faPhoneMissed,
        color: palette.danger,
      };
    }

    if (item.direction === "inbound") {
      return {
        icon: faPhoneArrowDownLeft,
        color: palette.grey,
      };
    }

    return {
      icon: faPhoneArrowUpRight,
      color: item?.[OUTBOUND_MISSED_CALL_KEY] ? palette.red : palette.grey,
    };
  }, [item]);

  return (
    <TouchableWithoutFeedback
      onPress={async () => {
        setSelected(item);
        if (conversation?.id !== item?.conversation_id) {
          const title = item?.author?.name || phoneNumber;
          setTempHeader({
            main:
              isCustomerSaved || canSeeCustomerContact
                ? title
                : "Unknown Customer",
            sub: item?.author?.parent?.name,
          });
          await setConversationById(item?.conversation_id, true);
        }
      }}
    >
      <View
        style={[
          styles.call_log_list__item,
          {
            backgroundColor:
              selected && selected?.id === item?.id
                ? palette.grey_selection
                : palette.grey_bg,
            cursor: "pointer",
            fontFamily: "OpenSans_400Regular",
          },
        ]}
      >
        <Row mt={12} mb={12}>
          <Col>
            <Row style={{ height: "100%" }}>
              <Col
                left
                maxWidth={24}
                pr={8}
                mt={4}
                style={{ justifyContent: "center" }}
                mr={4}
              >
                <FontAwesomeIcon {...{ size: 16, ...iconProps }} />
              </Col>
              <Col>
                <Row center style={{ justifyContent: "space-between", gap: 8 }}>
                  <View style={commonStyles.row_normal}>
                    <Text
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={[
                        styles.call_log_list__item_name,
                        item.missed && styles.call_log_list__item_missed,
                      ]}
                    >
                      {isCustomerSaved || canSeeCustomerContact
                        ? item.author?.name
                        : "Unknown Customer"}
                    </Text>
                    {!!hasParent && (
                      <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={[styles.call_log_list__item_name]}
                      >
                        {`: ${parentName}`}
                      </Text>
                    )}
                  </View>
                  <Text
                    numberOfLines={1}
                    style={{ color: palette.label_grey, fontSize: 12 }}
                  >
                    {getSmartTimestamp(item.created_at)}
                  </Text>
                </Row>
                <Row center style={{ justifyContent: "space-between", gap: 8 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    {canSeeCustomerContact && (
                      <Text
                        numberOfLines={1}
                        style={[
                          styles.call_log_list__item_number,
                          item.missed && styles.call_log_list__item_missed,
                        ]}
                      >
                        {phoneNumber}
                      </Text>
                    )}
                    <View>
                      <Col>
                        <Row center style={{ gap: 8 }} mt={8}>
                          <IconButton
                            faPro
                            faProIcon={faPhone}
                            iconColor={palette.success}
                            onPress={onCallPress}
                            iconSize={12}
                            width={12}
                            height={12}
                            ml={0}
                            mr={0}
                            disabled={activeCall || !activeNumber}
                          />
                          <IconButton
                            faPro
                            faProIcon={faComments}
                            iconColor={palette.primary}
                            onPress={async () => {
                              setSelected(null);
                              if (conversation?.id !== item?.conversation_id) {
                                const title = item?.author?.name || phoneNumber;
                                setTempHeader({
                                  main:
                                    isCustomerSaved || canSeeCustomerContact
                                      ? title
                                      : "Unknown Customer",
                                  sub: item?.author?.parent?.name,
                                });
                                await setConversationById(
                                  item?.conversation_id,
                                  true,
                                );
                              }
                            }}
                            iconSize={12}
                            width={12}
                            height={12}
                            ml={0}
                            mr={0}
                          />
                        </Row>
                      </Col>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Col>
                      <Row style={{ gap: 16 }} mr={8}>
                        {item?.meta?.voicemail && (
                          <FontAwesomeIcon
                            icon={faVoicemail}
                            color={palette.success}
                            size={12}
                            style={{ paddingTop: 4 }}
                          />
                        )}
                        {item?.meta?.call_recording && (
                          <FontAwesomeIcon
                            icon={faVolume}
                            color={palette.success}
                            size={12}
                            style={{ paddingTop: 4 }}
                          />
                        )}
                      </Row>
                    </Col>
                    <Text
                      numberOfLines={1}
                      style={[styles.call_log_list__item_duration]}
                    >
                      {item.missed
                        ? "Missed"
                        : getCommaSeparatedHoursMinutesSeconds(
                            item.meta.duration,
                            "seconds",
                            true,
                          )}
                    </Text>
                  </View>
                </Row>
                <Row center style={{ justifyContent: "space-between", gap: 8 }}>
                  <Text
                    numberOfLines={1}
                    style={[
                      styles.call_log_list__item_number,
                      item.missed && styles.call_log_list__item_missed,
                    ]}
                  >
                    {item.twilio_number?.friendly_name}
                  </Text>

                  <Text
                    numberOfLines={1}
                    style={[styles.call_log_list__item_duration]}
                  >
                    {item?.called_by}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const NoCallsComponent = () => {
  return (
    <View style={[styles.no_calls_component]}>
      <Text style={[styles.no_calls_component_text]}>
        No calls have been logged
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: palette.grey_bg,
  },
  call_log_list__item: {
    paddingHorizontal: 16,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: palette.light_grey,
  },
  call_log_list__item_name: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 14,
  },
  call_log_list__item_number: {
    marginTop: 6,
    fontSize: 12,
  },
  call_log_list__item_duration: {
    marginTop: 4,
    fontSize: 12,
    color: palette.label_grey,
  },
  call_log_list__item_missed: {
    color: palette.danger,
  },
  no_calls_component: {
    padding: 16,
  },
  no_calls_component_text: {
    textAlign: "center",
    fontFamily: "acumin-pro, san-serif",
    color: palette.grey,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
  },
});

export default CallLogList;
