import { useContext, useState, useEffect, useMemo } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  Switch,
  View,
  ScrollView,
  ActivityIndicator,
} from "react-native";

import { Link, useHistory } from "react-router-dom";

import Heading from "~/components/Heading/";
import SolidButton from "~/components/Buttons/SolidButton/";

import UserContext from "~/contexts/UserContext";
import NumberContext from "~/contexts/NumberContext";

import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import CustomRecordingsContext from "../../../contexts/CustomRecordingsContext";
import ReactAudioPlayer from "react-audio-player";
import StyledPicker from "../../../components/StyledPicker";
import { GREETING_TYPES } from "../../../constants/mediaTypes";
import FieldPulse from "../../../services/fieldpulse";
import CommanModal from "../../../modals/CommanModal/index.web";
import { universalModalOpenEvent } from "../../../modals/UniversalModal/index.web";
import { PhoneNumber } from "../../../models/PhoneNumber";
import {
  ALTERNATIVE_NUMBER,
  CALL_ROUTING_OPTIONS,
  FORWARDING_ENABLED,
  MAX_SEQ_CALL,
  PHONE_NUMBER_KEYS,
  PHONE_TREE_ENABLED,
  TRANSFER_ENABLED,
} from "../../../constants/phoneNumber";
import NumbersContext from "../../../contexts/NumbersContext";
import PhoneNumbers from "../../../models/PhoneNumbers";
import { Strings } from "../../../constants/strings";
import {
  CALLER_ID_IN_ACTIVE_STATUS,
  CALLER_ID_STATUSES,
} from "../../../constants/callerId";
import { isValidCallerId } from "../../../helpers/callerId";
import Engage from "../../../services/engage";
import PhoneInput from "../../../components/PhoneInput";
import LoadingIndicator from "../../../components/LoadingIndicatior/index.web";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import commonStyles from "../../../styles/common";
const linkStyle = {
  color: palette.primary_light,
  fontFamily: "OpenSans_600SemiBold",
  textDecorationStyle: "none",
};

const NumberEditSettings = () => {
  const history = useHistory();
  const { user, getUser, isAdmin, isCampaignVerified, hasFlicentCampaign } =
    useContext(UserContext);
  const [loadingAll, setLoadingAll] = useState(true);

  const {
    saving,
    loading: loadingNumber,
    number,
    dirty,
    saveNumber,
    deleteNumber,
    updateNumber,
    changed,
    getNumber,
  } = useContext(NumberContext);

  const phoneNumber = new PhoneNumber(number);

  const onChange = (key) => (value) => {
    updateNumber({ [key]: value });
  };

  const greeting_type = phoneNumber.voicemailGreetingType;
  const greeting_url = phoneNumber.voicemailGreetingUrl;
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [leadSourceEnabled, setLeadSourceEnabled] = useState(
    phoneNumber.hasLeadSource,
  );

  const [active, setActive] = useState(
    greeting_type === GREETING_TYPES.AUDIO
      ? greeting_url === null
        ? "-1"
        : greeting_url
      : "-1",
  );

  const { customRecordings, getCustomRecordings, loading } = useContext(
    CustomRecordingsContext,
  );

  useEffect(() => {
    let t = setTimeout(() => {
      if (!loadingNumber && !loading) {
        setLoadingAll(false);
      } else {
        setLoadingAll(true);
      }
    }, 10);

    return () => {
      clearTimeout(t);
    };
  }, [loadingNumber, loading]);

  useEffect(() => {
    if (saving) {
      return () => {
        getUser();
      };
    }
  }, [saving, getUser]);

  useEffect(() => {
    getNumber();
  }, []);

  const onAssignmentsPress = () => {
    if (user?.access_level === "admin") {
      history.push("/settings/numbers#assignees", { number });
    }
  };

  const onManagersPress = () => {
    if (user?.access_level === "admin") {
      history.push("/settings/numbers#managers", { number });
    }
  };

  const onManagePhoneTreePress = () => {
    history.push("/settings/numbers#phone_tree", { number });
  };

  const onSavePress = () => saveNumber();

  const onDeletePress = () => {
    universalModalOpenEvent({
      message: "Are you sure you want to delete this number?",
      actionButton: {
        onPress: async () => {
          await deleteNumber();
          history.push("/settings/numbers");
        },
        label: "Yes",
      },
    });
  };

  useEffect(() => {
    if (phoneNumber.isAudioRecordingVoicemail) getCustomRecordings();
  }, [phoneNumber.isAudioRecordingVoicemail]);

  useEffect(() => {
    if (!loadingNumber) {
      const greeting_url = phoneNumber.voicemailGreetingUrl;
      setActive(
        phoneNumber.isAudioRecordingVoicemail
          ? greeting_url === null
            ? "-1"
            : greeting_url
          : "-1",
      );
    }
  }, [loadingNumber]);

  useEffect(() => {
    if (
      !loading &&
      (!active || active === "-1") &&
      Array.isArray(customRecordings) &&
      customRecordings.length > 0 &&
      phoneNumber.isAudioRecordingVoicemail
    ) {
      setActive(customRecordings[0].url);
      updateNumber({
        voicemail_greeting_url: customRecordings[0].url,
      });
    }

    if (
      !phoneNumber.isAudioRecordingVoicemail &&
      phoneNumber.voicemailGreetingUrl
    ) {
      setActive("-1");
      updateNumber({
        voicemail_greeting_url: null,
      });
    }
  }, [
    loading,
    active,
    customRecordings,
    phoneNumber.isAudioRecordingVoicemail,
  ]);

  useEffect(() => {
    if (phoneNumber.phoneTreeEnabled && !phoneNumber.hasTwilioFlowAttached) {
      setModalIsVisible(true);
    } else {
      setModalIsVisible(false);
    }
  }, [phoneNumber.hasTwilioFlowAttached, phoneNumber.phoneTreeEnabled]);

  useEffect(() => {
    if (!phoneNumber.transferEnabled && phoneNumber.transferCalls?.length > 0) {
      updateNumber({
        [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: [],
      });
    }
  }, [phoneNumber.transferEnabled, phoneNumber.transferCalls]);

  const onCallRoutingRouteChange = (route) => {
    let data = {
      [PHONE_NUMBER_KEYS.PHONE_TREE_ENABLED]: false,
      [PHONE_NUMBER_KEYS.TRANSFER_ENABLED]: false,
      [PHONE_NUMBER_KEYS.FORWARDING_ENABLED]: false,
    };

    if (route === null) {
      updateNumber(data);
      return;
    }

    switch (route) {
      case PHONE_TREE_ENABLED:
        data[PHONE_TREE_ENABLED] = true;
        data[TRANSFER_ENABLED] = false;
        data[FORWARDING_ENABLED] = false;
        break;

      case TRANSFER_ENABLED:
        data[PHONE_TREE_ENABLED] = false;
        data[TRANSFER_ENABLED] = true;
        data[FORWARDING_ENABLED] = false;
        break;

      case FORWARDING_ENABLED:
        data[PHONE_TREE_ENABLED] = false;
        data[TRANSFER_ENABLED] = false;
        data[FORWARDING_ENABLED] = true;
        break;

      default:
        break;
    }

    updateNumber(data);
  };

  const handleTemplateManage = () => {
    setModalIsVisible(false);
    history.push("/settings/numbers#phone_tree", { number });
  };

  const onLeadSourceToggle = (value) => {
    if (!value)
      updateNumber({
        lead_source_id: null,
        lead_source_name: null,
      });
    setLeadSourceEnabled(value);
  };

  if (loadingAll) return <LoadingIndicator title={"Loading Phone Line"} />;

  return (
    <ScrollView style={{ height: "100%" }}>
      <CommanModal
        isVisisble={modalIsVisible}
        message="Your phone tree is currently empty. Click here to set up your phone tree."
        buttonAction={handleTemplateManage}
        buttonTitle="Set Up Phone Tree"
      />
      <View>
        <HeadingBar phoneNumber={phoneNumber.number} />
        <Row pl={16} pr={16}>
          <Col size={8}>
            <FriendlyName
              value={phoneNumber.friendlyName}
              onChange={onChange(PHONE_NUMBER_KEYS.FRIENDLY_NAME)}
              isAdmin={isAdmin}
            />
            <CallMasking
              caller_id={phoneNumber.callerId}
              callerIdStatus={phoneNumber.callerIdStatus}
              number_id={phoneNumber.id}
              isAdmin={isAdmin}
              onUpdate={updateNumber}
              isApprovedOrRejected={phoneNumber.isCallerIdApprovedOrRejected}
              isInActive={phoneNumber.isCallerIdInActive}
              editable={phoneNumber.isCallerIdEditable}
              isCampaignVerified={isCampaignVerified}
              hasFlicentCampaign={hasFlicentCampaign}
            />
            <RecordCalls
              value={phoneNumber.recordCall}
              onChange={onChange(PHONE_NUMBER_KEYS.RECORD_CALL)}
              isAdmin={isAdmin}
            />
            <LeadSourceToggle
              value={leadSourceEnabled}
              onChange={onLeadSourceToggle}
              isAdmin={isAdmin}
            />
            <LeadSource
              enabled={leadSourceEnabled}
              onSelect={updateNumber}
              selectedValue={phoneNumber.leadSourceId}
              isAdmin={isAdmin}
            />
            <AssignedUsers
              isAdmin={isAdmin}
              onPress={onAssignmentsPress}
              label={phoneNumber.getAssigneesName()}
              hasAssignedUsers={phoneNumber.hasAssignees}
            />
            <Managers
              onPress={onManagersPress}
              label={phoneNumber.getManagersNames()}
              hasAssignedManagers={phoneNumber.hasManagers}
              isAdmin={isAdmin}
              allowIncomingCallsToManagers={phoneNumber.allowCallsToManagers}
              onToggleChange={onChange(
                PHONE_NUMBER_KEYS.ALLOW_CALLS_TO_MANAGERS,
              )}
            />
            <VoicemailType
              isAdmin={isAdmin}
              onChange={onChange(PHONE_NUMBER_KEYS.VOICEMAIL_GREETING_TYPE)}
              isSelectedDefault={phoneNumber.isDefaultVoicemail}
              isSelectedTextToSpeech={phoneNumber.isTextToSpeechVoicemail}
              isSelectedAudioRecording={phoneNumber.isAudioRecordingVoicemail}
            />
            <VoicemailResponse
              isAdmin={isAdmin}
              isSelectedAudioRecording={phoneNumber.isAudioRecordingVoicemail}
              isSelectedTextToSpeech={phoneNumber.isTextToSpeechVoicemail}
              updateNumber={updateNumber}
              selectedRecordingUrl={phoneNumber.voicemailGreetingUrl}
              setSelectedRecordingUrl={setActive}
              loading={loading}
              customRecordings={customRecordings}
              voicemailGreeting={phoneNumber.voicemailGreeting}
            />
            <CallRoutingPicker
              isAdmin={isAdmin}
              callRoutingRoute={phoneNumber.callRoutingRoute}
              onChange={onCallRoutingRouteChange}
            />
            <CallRoutingResponse
              isAdmin={isAdmin}
              callRoutingRoute={phoneNumber.callRoutingRoute}
              id={phoneNumber.id}
              forwardTo={phoneNumber.forwardTo}
              onForwardToChange={onChange(PHONE_NUMBER_KEYS.FORWARD_TO)}
              saving={saving}
              onManagePhoneTreePress={onManagePhoneTreePress}
              onChange={updateNumber}
              transferCallNumbers={phoneNumber.transferCalls}
            />
            <FormButtons
              isAdmin={isAdmin}
              saving={saving}
              onSavePress={onSavePress}
              onDeletePress={onDeletePress}
              dirty={dirty}
              phoneNumber={phoneNumber}
              changed={changed}
            />
          </Col>
        </Row>
      </View>
    </ScrollView>
  );
};

const FriendlyName = ({ value, onChange, isAdmin }) => {
  return (
    <>
      <Row pt={16}>
        <Heading size={5} mb={0} mt={0}>
          Friendly Name
        </Heading>
      </Row>

      <Row pt={8} left>
        <Col>
          <TextInput
            textContentType="telephoneNumber"
            value={value}
            selectTextOnFocus
            style={[styles.friendly_name_input]}
            onChangeText={onChange}
            editable={isAdmin}
          />
        </Col>
      </Row>
    </>
  );
};
const HeadingBar = ({ phoneNumber }) => {
  return (
    <Row pl={16} pr={16} style={styles.phoneNumberHeading}>
      <Col leftCenter>
        <Heading size={4} pb={8} mb={0}>
          {phoneNumber}
        </Heading>
      </Col>
    </Row>
  );
};

const RecordCalls = ({ value, onChange, isAdmin }) => {
  return (
    <Row pt={16}>
      <Col size={10}>
        <Heading size={5} mb={0} mt={0}>
          Record Calls
        </Heading>
      </Col>
      <Col rightCenter size={2} pt={4} pr={16}>
        <Switch value={value} onValueChange={onChange} disabled={!isAdmin} />
      </Col>
    </Row>
  );
};

const LeadSourceToggle = ({ value, onChange, isAdmin }) => {
  return (
    <Row pt={16}>
      <Col size={10}>
        <Heading size={5} mb={0} mt={0}>
          Automatically Designated Lead Source
        </Heading>
      </Col>
      <Col rightCenter size={2} pt={4} pr={16}>
        <Switch value={value} onValueChange={onChange} disabled={!isAdmin} />
      </Col>
    </Row>
  );
};

const LeadSource = ({ enabled, onSelect, selectedValue, isAdmin }) => {
  const [leadSources, setLeadSources] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLeadSources = async () => {
      setLoading(true);
      try {
        const res = await FieldPulse.getLeadSources();
        if (!res.error) {
          setLeadSources(res?.response || []);
        }
      } catch (e) {
        console.warn(e);
      }
      setLoading(false);
    };
    if (enabled) getLeadSources();
  }, [enabled]);

  const options = useMemo(
    () => [
      { name: "Select Lead Source", value: "" },
      ...(leadSources || []).map((source) => ({
        name: source.name,
        value: source.id,
      })),
    ],
    [leadSources],
  );

  if (enabled) {
    return loading ? (
      <LoadingLeadSource />
    ) : (
      <StyledPicker
        options={options}
        border
        style={{ minWidth: "100%", marginVertical: 8 }}
        selectedValue={selectedValue || ""}
        disabled={!isAdmin}
        onValueChange={(value) => {
          if (value) {
            const selectedSource =
              (leadSources || []).find(
                (source) => `${source.id}` === `${value}`,
              ) || {};
            onSelect({
              lead_source_id: value,
              lead_source_name: selectedSource.name || "",
            });
          }
        }}
      />
    );
  } else {
    return null;
  }
};

const LoadingLeadSource = () => (
  <Row pt={16}>
    <Col>
      <View style={styles.loading_lead_source}>
        <Heading size={5} mb={0} numberOfLines={null}>
          Loading Lead Sources
        </Heading>
      </View>
    </Col>
  </Row>
);

const AssignedUsers = ({ isAdmin, onPress, hasAssignedUsers, label }) => {
  return (
    <>
      <Row pt={32} mb={16}>
        <Col>
          <Heading size={5} mb={0} mt={0}>
            Assigned Users (Primary Line)
          </Heading>
          <Heading size={6} mb={0} mt={10} color={palette.grey}>
            Assigned users can answer incoming and make outgoing calls on this
            line.
          </Heading>
        </Col>
      </Row>
      <Row pt={8}>
        <Col style={styles.managers_col}>
          <Text
            style={[
              styles.managers_label,
              { color: hasAssignedUsers ? palette.primary : palette.grey },
            ]}
            onPress={isAdmin ? onPress : null}
          >
            {label || "Click to assign to a user"}
          </Text>
        </Col>
      </Row>
    </>
  );
};

const Managers = ({
  hasAssignedManagers,
  onPress,
  allowIncomingCallsToManagers,
  label,
  onToggleChange,
  isAdmin,
}) => {
  return (
    <>
      <Row pt={32} mb={16}>
        <Col>
          <Heading size={5} mb={0} mt={0}>
            Managers
          </Heading>
          <Row pt={8}>
            <Col size={10}>
              <Heading size={5} mb={0} mt={0}>
                Send Incoming Calls to Managers
              </Heading>
            </Col>
            <Col rightCenter size={2} pt={4} pr={16}>
              <Switch
                value={allowIncomingCallsToManagers}
                onValueChange={onToggleChange}
                disabled={!isAdmin}
              />
            </Col>
          </Row>
          <Heading
            size={6}
            mb={0}
            mt={10}
            color={palette.grey}
            numberOfLines={null}
          >
            Managers can view all activity on this line, including voicemails.
            They can view and send text messages,{" "}
            {allowIncomingCallsToManagers
              ? "and can make calls."
              : "and can answer or make calls."}
          </Heading>
          <Heading
            size={6}
            mb={0}
            mt={10}
            color={palette.grey}
            numberOfLines={null}
          >
            Note: Users assigned as managers that have not been assigned a
            number will be assigned to this number.
          </Heading>
        </Col>
      </Row>
      <Row pt={8}>
        <Col style={styles.managers_col}>
          <Text
            onPress={isAdmin ? onPress : null}
            style={[
              styles.managers_label,
              {
                color: hasAssignedManagers ? palette.primary : palette.grey,
              },
            ]}
          >
            {label || "Click to add managers"}
          </Text>
        </Col>
      </Row>
    </>
  );
};

const VoicemailType = ({
  isSelectedDefault,
  isSelectedTextToSpeech,
  isSelectedAudioRecording,
  onChange,
  isAdmin,
}) => {
  const VoicemailGreetingHeader = () => (
    <Row mt={32} style={commonStyles.alignCenter}>
      <Heading size={5}>Custom Voicemail Greeting</Heading>
      <InfoIconButton link={Strings.VOICEMAIL_DOCS_URL} />
    </Row>
  );

  return (
    <>
      <VoicemailGreetingHeader />
      <Row mt={16}>
        <SolidButton
          label="Default"
          color={isSelectedDefault ? palette.primary : palette.white}
          onPress={() => {
            onChange(GREETING_TYPES.DEFAULT);
          }}
          disabled={!isAdmin}
        />
        <SolidButton
          label="Text to Speech"
          color={isSelectedTextToSpeech ? palette.primary : palette.white}
          onPress={() => {
            onChange(GREETING_TYPES.TEXT);
          }}
          disabled={!isAdmin}
          style={{ marginLeft: 8 }}
        />
        <SolidButton
          label="Audio Recording"
          color={isSelectedAudioRecording ? palette.primary : palette.white}
          style={{ marginLeft: 8 }}
          disabled={!isAdmin}
          onPress={() => {
            onChange(GREETING_TYPES.AUDIO);
          }}
        />
      </Row>
    </>
  );
};

const VoicemailResponse = ({
  isSelectedAudioRecording,
  isSelectedTextToSpeech,
  loading,
  customRecordings,
  updateNumber,
  selectedRecordingUrl,
  setSelectedRecordingUrl,
  voicemailGreeting,
  isAdmin,
}) => {
  const options = customRecordings
    ?.filter((audio) => audio?.url !== null)
    ?.map((recording) => ({
      label: recording.title,
      value: recording.url,
    }));

  if (isSelectedAudioRecording) {
    return (
      <Row>
        <Row mt={16}>
          {loading && (
            <View style={styles.loading_audio_samples}>
              <Heading size={5} mb={0} numberOfLines={null}>
                Loading Audio Samples
              </Heading>
            </View>
          )}
          {!loading && options.length === 0 && (
            <Heading size={5} mb={0} numberOfLines={null}>
              You don't have any audio samples. You can add them{" "}
              <Link to="/settings/voicenotes" style={linkStyle}>
                <Text>here</Text>
              </Link>
              .
            </Heading>
          )}
          {!loading && options.length > 0 && (
            <Col>
              <Row>
                <StyledPicker
                  options={options}
                  selectedValue={selectedRecordingUrl}
                  onValueChange={(val) => {
                    setSelectedRecordingUrl(val);
                    updateNumber({ voicemail_greeting_url: val });
                  }}
                  disabled={!isAdmin}
                  border
                />
              </Row>
              {selectedRecordingUrl && (
                <Row mt={16}>
                  <ReactAudioPlayer src={selectedRecordingUrl} controls />
                </Row>
              )}
            </Col>
          )}
        </Row>
      </Row>
    );
  } else if (isSelectedTextToSpeech) {
    return (
      <Row pt={8}>
        <Col>
          <TextInput
            placeholder="Enter a custom voicemail greeting for this line or leave blank to use default greeting."
            multiline
            disabled={!isAdmin}
            numberOfLines={3}
            value={voicemailGreeting}
            onChangeText={(value) =>
              updateNumber({ voicemail_greeting: value })
            }
            style={styles.voicemail_greeting_input}
          />
        </Col>
      </Row>
    );
  } else {
    if (!isAdmin) return null;
    return (
      <Row pt={8}>
        <Heading size={6} mb={0} mt={16} numberOfLines={null}>
          You can view/edit Default Voicemail{" "}
          <Link to="/settings/voicemails" style={linkStyle}>
            <Text>here</Text>
          </Link>
          .
        </Heading>
      </Row>
    );
  }
};

function CallRoutingPicker({ callRoutingRoute, onChange, isAdmin }) {
  return (
    <View
      style={{
        marginVertical: 8,
        alignItems: "flex-start",
      }}
    >
      <Row style={commonStyles.alignCenter}>
        <Heading size={5} mb={0} mt={0}>
          Call Routing
        </Heading>
        <InfoIconButton link={Strings.CALL_ROUTING_DOCS_URL} />
      </Row>
      <StyledPicker
        selectedValue={callRoutingRoute}
        onValueChange={onChange}
        options={CALL_ROUTING_OPTIONS}
        border
        style={{ minWidth: 200 }}
        disabled={!isAdmin}
      />
    </View>
  );
}

const CallRoutingResponse = ({
  callRoutingRoute,
  onManagePhoneTreePress,
  id,
  forwardTo,
  onForwardToChange,
  saving,
  onChange,
  transferCallNumbers,
  isAdmin,
}) => {
  if (!callRoutingRoute) {
    return null;
  }

  if (callRoutingRoute === PHONE_NUMBER_KEYS.FORWARDING_ENABLED) {
    return (
      <Row>
        <Col>
          <TextInput
            value={forwardTo}
            placeholder="Forwarding phone number"
            selectTextOnFocus
            editable={isAdmin}
            style={[styles.forward_to_input]}
            onChangeText={(text) => {
              onForwardToChange(text);
            }}
          />
        </Col>
      </Row>
    );
  }

  if (callRoutingRoute === PHONE_NUMBER_KEYS.PHONE_TREE_ENABLED) {
    return (
      <Row>
        <SolidButton
          label="Manage Phone Tree"
          onPress={onManagePhoneTreePress}
          disabled={saving || !isAdmin}
        />
      </Row>
    );
  }
  return (
    <SequentialCall
      id={id}
      onChange={onChange}
      transferCallNumbers={transferCallNumbers}
      isAdmin={isAdmin}
    />
  );
};

const SequentialCall = ({
  id,
  transferCallNumbers = [],
  onChange,
  isAdmin,
}) => {
  const { numbers } = useContext(NumbersContext);
  const phoneNumbers = new PhoneNumbers(numbers);
  const filteredPhoneNumbers = phoneNumbers.getPhoneNumbersForCallRouting(id);
  const OPTIONS_LENGTH = 3;

  useEffect(() => {
    if (transferCallNumbers.length === 0 && filteredPhoneNumbers.length > 0) {
      onChange({
        [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: [filteredPhoneNumbers[0].value],
      });
    }
    if (!transferCallNumbers.length && !filteredPhoneNumbers.length) {
      onChange({
        [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: [""],
      });
    }
  }, [transferCallNumbers.length, filteredPhoneNumbers.length]);

  const handleChange =
    (index, isAlternate = false) =>
    (value) => {
      const newTransferCallNumbers = [...transferCallNumbers];

      const prevIndex = newTransferCallNumbers.indexOf(value);

      let indexToChange = index;

      if (prevIndex !== -1 && !isAlternate) {
        newTransferCallNumbers.splice(prevIndex, 1);
        if (prevIndex < index) {
          indexToChange = index - 1;
        }
      }

      newTransferCallNumbers[indexToChange] = value;

      let props = {
        [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: newTransferCallNumbers,
      };
      onChange(props);
    };

  const addNew = () => {
    const newTransferCallNumbers = [...transferCallNumbers];

    const filteredNumbers = filteredPhoneNumbers.filter(
      (number) => !transferCallNumbers.includes(number.value),
    );
    newTransferCallNumbers.push(
      filteredNumbers.length > 0 ? filteredNumbers[0].value : "",
    );
    onChange({
      [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: newTransferCallNumbers,
    });
  };

  const onRemove = (index) => () => {
    const newTransferCallNumbers = [...transferCallNumbers];
    newTransferCallNumbers.splice(index, 1);
    let props = {
      [PHONE_NUMBER_KEYS.TRANSFER_CALLS]: newTransferCallNumbers,
    };
    onChange(props);
  };

  const checkIsAlternativeNumber = (number) => {
    return !filteredPhoneNumbers.find((item) => item.value === number);
  };

  if (!isAdmin)
    return (
      <StaticSequentialCallNumbers transferCallNumbers={transferCallNumbers} />
    );

  return (
    <>
      {transferCallNumbers.map((_, index) => {
        let options = [];
        options.push(...filteredPhoneNumbers);
        options.push({
          name: "Alternative Number",
          value: ALTERNATIVE_NUMBER,
        });

        const isAlternativeNumber = checkIsAlternativeNumber(
          transferCallNumbers[index],
        );

        const isSelectedAlternativeOption =
          transferCallNumbers[index] === ALTERNATIVE_NUMBER;

        return (
          <View key={index} style={styles.sequential_call_number_container}>
            <StyledPicker
              selectedValue={
                isAlternativeNumber
                  ? ALTERNATIVE_NUMBER
                  : transferCallNumbers[index]
              }
              onValueChange={handleChange(index)}
              options={options}
              border
              disabled={!isAdmin}
              style={{ flex: 1, minWidth: 200 }}
            />
            {isAlternativeNumber && (
              <AlternativeNumber
                alternativeNumber={
                  isSelectedAlternativeOption ? "" : transferCallNumbers[index]
                }
                onChange={handleChange(index, true)}
                isAdmin={isAdmin}
              />
            )}
            {index > 0 && (
              <SolidButton
                label="Remove"
                disabled={!isAdmin}
                onPress={onRemove(index)}
                color={palette.white}
                style={{ borderWidth: 0 }}
              />
            )}
          </View>
        );
      })}
      {transferCallNumbers.length < OPTIONS_LENGTH && (
        <Row>
          <Col maxWidth={200}>
            <SolidButton
              label="Add Number"
              onPress={addNew}
              disabled={!isAdmin}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const StaticSequentialCallNumbers = ({ transferCallNumbers = [] }) => {
  const Numbers = () => {
    if (transferCallNumbers.length === 0) return null;

    const numbers = transferCallNumbers.map((number) => {
      return (
        <View style={styles.static_sequential_call_numbers}>
          <Text>{number}</Text>
        </View>
      );
    });

    return numbers;
  };
  return <Numbers />;
};

const FormButtons = ({
  onSavePress,
  saving,
  onDeletePress,
  phoneNumber,
  dirty,
  changed,
  isAdmin,
}) => {
  const disabled = !phoneNumber.canSavePhoneNumber(dirty, changed);
  if (!isAdmin) return null;

  return (
    <Row mt={32} mb={32}>
      <Col size={2} mr={16}>
        <SolidButton
          onPress={onSavePress}
          loading={saving}
          color={palette.primary}
          disabled={disabled}
          label="Save"
        />
      </Col>
      <View>
        <SolidButton
          label="Release Number"
          disabled={saving}
          color={palette.white}
          labelColor={palette.danger}
          onPress={onDeletePress}
        />
      </View>
    </Row>
  );
};

function CallMasking({
  caller_id,
  number_id,
  callerIdStatus,
  onUpdate,
  isApprovedOrRejected,
  isCampaignVerified,
  isInActive,
  editable,
  isAdmin,
  hasFlicentCampaign,
}) {
  const showFlicentCampaignError = hasFlicentCampaign && isAdmin;
  const history = useHistory();
  const [callerId, setCallerId] = useState(caller_id);
  const [editing, setEditing] = useState(isInActive);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(
    showFlicentCampaignError
      ? Strings.FLICENT_CAMPAIGN_CALLER_ID_MESSAGE
      : false,
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const isCallerIDValid = useMemo(() => {
    if (!callerId || !callerId?.trim()) return false;
    return isValidCallerId(callerId);
  }, [callerId]);

  const onSubmit = async () => {
    if (isCallerIDValid) {
      setSaving(true);
      setError(false);
      try {
        const res = await Engage.submitCallerID(number_id, callerId);
        if (res?.error) {
          setError(res?.message);
        } else {
          onUpdate(res?.response);
          setEditing(false);
        }
      } catch {
        setError("Something went wrong. Please try again later.");
      } finally {
        setSaving(false);
      }
    }
  };

  const onDelete = async () => {
    setDeleteModalVisible(false);
    setSaving(true);

    try {
      const res = await Engage.RemoveCallerID(number_id);
      if (res?.error) {
        setError(res?.message);
      } else {
        onUpdate(res?.response);
        setEditing(true);
      }
    } catch (e) {
      console.error(e);
      setError("Something went wrong. Please try again later.");
    } finally {
      setSaving(false);
    }
  };

  const getCallerIdStatusLabel = () => {
    return (
      CALLER_ID_STATUSES[callerIdStatus] ||
      CALLER_ID_STATUSES[CALLER_ID_IN_ACTIVE_STATUS]
    );
  };

  const onA2P10DLCSettingsPress = () => {
    history.push("/settings/10dlc");
  };

  const HelpText = () => {
    if (!!error) return <Text style={{ color: palette.red }}>{error}</Text>;
    if (!editing || isCallerIDValid) return null;
    if (!callerId || callerId?.trim()?.length < 3) return null;

    return (
      <>
        <Row>
          <Text style={{ color: palette.red }}>Incorrect Input</Text>
        </Row>
        <Row>
          <Text style={{ color: palette.red }}>
            Caller ID must follow specific guidelines: up to 15 characters,
            unique, starting with a letter, and composed of letters, numbers,
            periods, commas, or spaces.
          </Text>
        </Row>
      </>
    );
  };

  const onDeleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const onDeleteRequest = () => {
    setDeleteModalVisible(true);
  };

  const { label, color } = getCallerIdStatusLabel();

  if (!isCampaignVerified) return null;

  return (
    <>
      <Row
        pt={16}
        center
        style={{
          justifyContent: "space-between",
          gap: 16,
        }}
      >
        <View style={commonStyles.row_center}>
          <Heading
            size={5}
            mb={0}
            mt={0}
            color={showFlicentCampaignError ? palette.red : palette.black}
          >
            Caller ID
          </Heading>
          <InfoIconButton link={Strings.CALLER_ID_DOCS_URL} />
        </View>
        {!showFlicentCampaignError && (
          <SolidButton label={label} color={color} onPress={null} />
        )}
      </Row>

      <Row pt={8} left>
        <Col>
          <TextInput
            value={callerId || ""}
            selectTextOnFocus
            style={[
              styles.friendly_name_input,
              showFlicentCampaignError && styles.caller_id_invalid,
            ]}
            onChangeText={setCallerId}
            maxLength={15}
            editable={
              editable && editing && isAdmin && !showFlicentCampaignError
            }
            placeholder="Caller ID"
            nativeID="caller_id"
          />
          <View style={styles.caller_id_button}>
            {saving && <ActivityIndicator color={palette.dark_blue} />}
            {editable && !saving && isAdmin && !showFlicentCampaignError && (
              <SolidButton
                label={isApprovedOrRejected ? "Remove" : "Submit"}
                color={palette.white}
                lessPadding
                style={{
                  borderWidth: 0,
                }}
                labelColor={
                  isApprovedOrRejected ? palette.red : palette.dark_blue
                }
                onPress={isApprovedOrRejected ? onDeleteRequest : onSubmit}
                disabled={editing && !isCallerIDValid}
              />
            )}
          </View>
        </Col>
      </Row>
      <Row mt={4}>
        <HelpText />
        {showFlicentCampaignError && (
          <Text style={linkStyle} onPress={onA2P10DLCSettingsPress}>
            Update Here
          </Text>
        )}
      </Row>
      <CommanModal
        isVisible={deleteModalVisible}
        message="Do you confirm to remove Call Masking from this phone number?"
        buttonAction={onDelete}
        buttonTitle="Yes"
        closeable
        onClose={onDeleteModalClose}
      />
    </>
  );
}

const AlternativeNumber = ({
  alternativeNumber = "",
  onChange = () => {},
  isAdmin = false,
}) => {
  return (
    <View style={styles.alternate_number_container}>
      <PhoneInput
        onChange={onChange}
        defaultCountryCode={"+1"}
        country="US"
        defaultValue={alternativeNumber || ""}
        disabled={!isAdmin}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  friendly_name_input: {
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
    outlineColor: palette.light_grey,
    outlineOffset: 0,
    outlineWidth: 0,
    borderRadius: 4,
  },
  loading_lead_source: {
    backgroundColor: palette.bg_light_grey,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
  },
  managers_col: {
    borderWidth: 1,
    borderColor: palette.light_grey,
    borderRadius: 4,
    minHeight: 45,
  },
  managers_label: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
  },
  loading_audio_samples: {
    backgroundColor: palette.bg_light_grey,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
  },
  voicemail_greeting_input: {
    width: "100%",
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    outlineColor: palette.light_grey,
    outlineOffset: 0,
    outlineWidth: 0,
    borderRadius: 4,
  },
  default_voicemail_link_container: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 4,
    backgroundColor: palette.lighter_grey,
  },
  default_voicemail_link: {},
  forward_to_input: {
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
    borderRadius: 4,
  },
  phoneNumberHeading: {
    height: 75,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
  static_sequential_call_numbers: {
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginBottom: 8,
    maxWidth: 200,
  },
  static_sequential_call_numbers_text: {
    fontFamily: "OpenSans_600SemiBold",
  },
  sequential_call_number_container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 8,
  },
  static_sequential_call_numbers_alert: {
    borderColor: palette.red,
    backgroundColor: palette.light_red,
    width: 200,
  },
  misconfigured_warning_text: {
    color: palette.red,
  },
  caller_id_button: {
    zIndex: 100,
    position: "absolute",
    right: 16,
    top: "50%",
    transform: "translateY(-50%)",
  },
  caller_id_invalid: {
    borderColor: palette.red,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: palette.light_red,
  },
  alternate_number_container: {
    maxWidth: 250,
    marginHorizontal: 8,
    borderWidth: 1,
    borderColor: palette.light_grey,
    borderRadius: 5,
    padding: 1,
  },
});

export default NumberEditSettings;
