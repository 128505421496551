import { useContext, useMemo, useState } from "react";
import { StyleSheet, Text, View } from "react-native-web";
import IconButton from "../../components/Buttons/IconButton";
import SolidButton from "../../components/Buttons/SolidButton";
import { CallsContext } from "../../contexts/CallsContext";
import { RecordingsContext } from "../../contexts/RecordingsContext";
import ThreadsContext from "../../contexts/ThreadsContext";
import { VoicemailsContext } from "../../contexts/VoicemailsContext";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import Engage from "../../services/engage";
import FieldPulse from "../../services/fieldpulse";
import palette from "../../styles/palette";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import ConversationContext from "../../contexts/ConversationContext";

const ConfirmDeleteModal = ({ location, history }) => {
  const { message = "", type, id = null } = location.state || {};
  const { refreshThreads } = useContext(ThreadsContext);
  const { clearConversation } = useContext(ConversationContext);
  const { refreshCallLogs } = useContext(CallsContext);
  const { deleteVoicemail, refreshVoicemails } = useContext(VoicemailsContext);
  const { deleteRecording, refreshRecordings } = useContext(RecordingsContext);
  const [loading, setLoading] = useState();

  const buttons = useMemo(
    () => [
      {
        label: "Yes",
        color: palette.danger,
        style: {
          marginRight: 8,
        },
        loading,
        onPress: async () => {
          switch (type) {
            case "all-calls":
              setLoading(true);
              await Engage.deleteCallHistory();
              await refreshCallLogs();
              setLoading(false);
              history.replace("/calls");
              break;
            case "one-call":
              setLoading(true);
              await Engage.deleteCall(id);
              await refreshCallLogs();
              setLoading(false);
              history.replace("/calls");
              break;
            case "one-conversation":
              setLoading(true);
              await Engage.deleteConversation(id);
              clearConversation();
              await refreshThreads();
              setLoading(false);
              history.replace("/messages");
              break;
            case "voicemail":
              setLoading(true);
              await deleteVoicemail(id);
              await refreshVoicemails();
              setLoading(false);
              history.replace("/voicemails");
              break;
            case "recording":
              setLoading(true);
              await deleteRecording(id);
              await refreshRecordings();
              setLoading(false);
              history.replace("/recordings");
              break;
            default:
              history.goBack();
          }
        },
      },
      {
        label: "Cancel",
        marginRight: 8,
        onPress: () => {
          history.goBack();
        },
      },
    ],
    [loading],
  );
  return (
    <View style={[styles.modal_overlay]} onPress={() => console.log("close")}>
      <View style={styles.modal_container}>
        <ModalHeader
          onClose={() => {
            history.goBack();
          }}
        />
        <View style={styles.modal__body}>
          <Text style={styles.body__message}>{message}</Text>
          <Row>
            {buttons.map((button, index) => (
              <SolidButton key={index} {...button} />
            ))}
          </Row>
        </View>
      </View>
    </View>
  );
};

const ModalHeader = ({ onClose }) => {
  return (
    <View style={[styles.modal__header]}>
      <Row pl={32} pr={8}>
        <Col leftCenter>
          <Text style={[styles.modal__header_title_text]}>
            Confirm Deletion
          </Text>
        </Col>
        <Col rightCenter>
          <IconButton
            onPress={onClose}
            faPro
            faProIcon={faXmark}
            iconSize={28}
            color={palette.primary}
            iconColor={palette.white}
          />
        </Col>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 60,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    paddingBottom: 8,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "500px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    borderRadius: 3,
  },
  body__message: {
    color: palette.black,
    fontSize: 16,
    fontFamily: "acumin-pro, san-serif",
    marginVertical: 10,
  },
});

export default ConfirmDeleteModal;
