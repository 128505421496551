import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Image,
} from "react-native";
import { useDropzone } from "react-dropzone";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import useMedia from "../../hooks/useMedia";

import { MediaContext } from "../../contexts/MediaContext";
import { RecordingContext } from "../../contexts/RecordingContext";
import SolidButton from "~/components/Buttons/SolidButton";
import { MEDIA_TYPES } from "../../constants/mediaTypes";
import { Field } from "../../components/PhoneTree/PhoneTreeForm/index.web";
import StyledInput from "../../components/StyledInput";
import IconButton from "../../components/Buttons/IconButton";
import Engage from "../../services/engage";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

export default function AudioUploadModal() {
  const {
    audioUploadVisible,
    setAudioUploadVisible,
    activeAudio,
    setActiveAudio,
    audioRecordVisible,
    setAudioRecordVisible,
  } = useContext(MediaContext);

  const { recording, setRecording } = useContext(RecordingContext);

  const isFullScreen = Dimensions.get("window").width < 960;
  const { assets, setAssets } = useMedia();

  const [tempAssets, setTempAssets] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [saving, setSaving] = useState(false);

  const editing = useMemo(() => {
    return activeAudio !== null;
  }, [JSON.stringify(activeAudio)]);

  const onDrop = (files) => {
    console.log("onDrop -> ", files);
    setTempAssets(
      files.map((f) => ({
        preview: URL.createObjectURL(f),
        f,
      })),
    );
  };

  const onRemove = () => {
    setTempAssets([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: MEDIA_TYPES.audio,
  });

  const onCloseModal = () => {
    if (!saving) {
      setRecording(null);
      setActiveAudio(null);
      setAudioUploadVisible(false);
    }
  };

  const onSubmit = async () => {
    setSaving(true);
    if (!editing) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      if (fileUrl && fileUrl.trim()) formData.append("file_url", fileUrl);
      if (tempAssets.length > 0) formData.append("file", tempAssets[0]?.f);
      if (recording !== null) {
        formData.append("file", recording.file);
      }
      await Engage.addCustomRecording(formData);
      setTempAssets([]);
      setRecording(null);
    } else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      await Engage.updateCustomRecording(activeAudio.id, formData);
    }
    setSaving(false);
    setActiveAudio(null);
    setAudioUploadVisible(false);
  };

  useEffect(() => {
    setTitle("");
    setDescription("");
    setFileUrl("");
    setTempAssets([]);
    if (activeAudio !== null) {
      setTitle(activeAudio.title);
      setDescription(activeAudio.description);
    }
  }, [audioUploadVisible, activeAudio]);

  const isFormValid = useMemo(() => {
    if (!title || title.trim().length === 0) return false;
    if (!editing) {
      if (
        tempAssets?.length === 0 &&
        (!fileUrl || fileUrl.trim().length === 0) &&
        (!recording ||
          recording === null ||
          !recording.useAudio ||
          !recording.file)
      )
        return false;
    }
    return true;
  }, [title, fileUrl, tempAssets, editing, recording]);

  if (!audioUploadVisible) return null;

  return (
    <TouchableWithoutFeedback onPress={onCloseModal}>
      <View style={[styles.modal_overlay]}>
        <TouchableWithoutFeedback onPress={() => {}}>
          <View
            style={[
              styles.modal_container,
              { width: isFullScreen ? "100%" : 600 },
            ]}
          >
            <Text style={[styles.modal__header_title_text]}>
              {editing ? "Edit" : "Add"} Audio Sample
            </Text>
            {!editing && (
              <Text style={[styles.modal__header_shadow_text]}>
                Audios under 5mb are supported.{"\n"} You can use file url or
                upload from your system.
              </Text>
            )}
            <View style={{ marginHorizontal: 32 }}>
              <Field
                label="Title"
                labelStyle={{ color: palette.white }}
                required
              >
                <StyledInput
                  value={title}
                  onChangeText={setTitle}
                  bold
                  nativeID="audio_title"
                />
              </Field>
              <Field label="Description" labelStyle={{ color: palette.white }}>
                <StyledInput
                  value={description}
                  onChangeText={setDescription}
                  multiline
                  bold
                  nativeID="audio_description"
                />
              </Field>

              {recording !== null && recording.useAudio && !editing && (
                <Row mt={32} mb={16} center>
                  <View
                    style={{
                      marginHorizontal: 32,
                      backgroundColor: palette.bg_light_grey,
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      borderRadius: 5,
                    }}
                  >
                    <Row center>
                      <Text numberOfLines={1}>Custom Recording</Text>
                      <IconButton
                        color={palette.danger}
                        faPro
                        faProIcon={faXmark}
                        iconSize={18}
                        width={22}
                        height={22}
                        onPress={() => {
                          setRecording(null);
                        }}
                      />
                    </Row>
                  </View>
                </Row>
              )}
              {(recording === null || !recording.useAudio) &&
                !editing &&
                tempAssets.length === 0 && (
                  <>
                    <Field
                      label="File Url"
                      labelStyle={{ color: palette.white }}
                    >
                      <StyledInput
                        value={fileUrl}
                        onChangeText={setFileUrl}
                        multiline
                        bold
                        nativeID="audio_file_url"
                      />
                    </Field>
                    {(!fileUrl || fileUrl.length === 0) && (
                      <SolidButton
                        style={{ marginTop: 16 }}
                        label="Record Audio"
                        color={palette.success}
                        onPress={() => setAudioRecordVisible(true)}
                      />
                    )}
                  </>
                )}
            </View>
            {(recording === null || !recording.useAudio) && !editing && (
              <Row mt={32} mb={16} center>
                {tempAssets.length === 0 ? (
                  <div
                    {...getRootProps()}
                    style={{
                      borderStyle: "dashed",
                      borderColor: palette.light_grey,
                      borderWidth: 2,
                      borderRadius: 4,
                      width: "100%",
                      height: 64,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      marginLeft: 32,
                      marginRight: 32,
                      // paddingHorizontal: 16,
                      // paddingVertical: 48,
                      paddingTop: 48,
                      paddingBottom: 48,
                      paddingLeft: 16,
                      paddingRight: 16,
                      cursor: "pointer",
                    }}
                  >
                    {fileUrl.length > 0 ? (
                      <Text style={[styles.modal__header_subtitle_text]}>
                        Please clear File Url to use upload/record
                        functionality.
                      </Text>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <Text style={[styles.modal__header_subtitle_text]}>
                            Drop file to attach.
                          </Text>
                        ) : (
                          <Text style={[styles.modal__header_subtitle_text]}>
                            Drag file you wish to attach here or click to select
                            a file.
                          </Text>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <View
                    style={{
                      marginHorizontal: 32,
                      backgroundColor: palette.bg_light_grey,
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      borderRadius: 5,
                    }}
                  >
                    <Row center>
                      <Text numberOfLines={1}>{tempAssets[0].f.name}</Text>
                      <IconButton
                        color={palette.danger}
                        faPro
                        faProIcon={faXmark}
                        iconSize={18}
                        width={22}
                        height={22}
                        onPress={onRemove}
                      />
                    </Row>
                  </View>
                )}
              </Row>
            )}
            <Row pl={32} pr={32} mt={16} style={{ gap: 16 }}>
              <Col left>
                <SolidButton
                  label="Cancel"
                  onPress={onCloseModal}
                  faIcon={faXmark}
                  color={palette.danger}
                  disabled={saving}
                />
              </Col>
              <Col right>
                <SolidButton
                  label="Submit"
                  onPress={onSubmit}
                  icon="check"
                  color={palette.success}
                  loading={saving}
                  disabled={!isFormValid}
                />
              </Col>
            </Row>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  modal__header: {
    height: 75,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontSize: 32,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_subtitle_text: {
    color: palette.white,
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_shadow_text: {
    color: palette.white,
    opacity: 0.6,
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.65)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
    zIndex: 100,
  },
  modal_container: {
    borderRadius: 8,
    justifyContent: "center",
    backgroundColor: palette.primary,
    padding: 16,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    transitionProperty: "right",
    transitionDelay: "100ms",
    transitionDuration: "200ms",
  },
});
