import React, { useCallback, useContext, useState } from "react";
import { TouchableWithoutFeedback, StyleSheet, View, Text } from "react-native";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import StyledInput from "../StyledInput/index";
import Col from "../../layouts/Col";
import Avatar from "../Avatar";
import useCustomerSearch from "../../hooks/useCustomerSearch";
import IconButton from "../Buttons/IconButton/index";
import ConversationContext from "../../contexts/ConversationContext";
import Engage from "../../services/engage";
import { faComments, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../contexts/UserContext";

const regExp = /[a-z]/i;

export default function RecipientSearchInput({ onPress }) {
  const {
    conversation,
    setParticipantFromCustomer,
    setConversation,
    clearConversation,
    setParticipant,
  } = useContext(ConversationContext);
  const { canSeeCustomerContact: showContact } = useContext(UserContext);

  const [recipientNumber, setRecipientNumber] = useState(
    onPress ? null : conversation?.participant?.phone_number,
  );

  const { customers } = useCustomerSearch({
    search: recipientNumber,
    limit: 3,
  });

  const onResetInput = () => {
    setRecipientNumber("");
    clearConversation();
  };

  const selectCustomer = useCallback(
    async (matchedCustomer, matchedNumber = {}) => {
      setRecipientNumber(matchedNumber.phone_number);

      if (onPress) {
        return onPress(matchedCustomer);
      }
      return setParticipantFromCustomer(
        matchedCustomer,
        matchedNumber.phone_number,
      );
    },
    [setRecipientNumber, setParticipantFromCustomer, onPress],
  );

  const onCustomerPress = useCallback(
    (matchedCustomer, matchedNumber = {}) =>
      async () => {
        let participant = await selectCustomer(matchedCustomer, matchedNumber);
        if (participant && !participant.error && !onPress) {
          await Engage.getConversationByParticipant({
            remote_id: matchedCustomer?.id,
          });
        }
      },
    [selectCustomer],
  );

  const onNewNumberPress = useCallback(async () => {
    const res = await Engage.getParticipantByPhone({
      phone: recipientNumber,
      // first_name: matchedCustomer?.first_name || "",
      // last_name: matchedCustomer?.last_name || "",
      // remote_id: matchedCustomer?.id,
    });

    if (res.conversation) {
      setConversation(res.conversation);
    }
    if (res.response) {
      const _participant = res.response;

      setParticipant(_participant);
    } else {
      setParticipant({ phone_number: recipientNumber });
    }
  }, [setParticipantFromCustomer, recipientNumber, setParticipant]);

  const onEnterPress = useCallback(
    async (e) => {
      if (e.nativeEvent.key !== "Enter") {
        return;
      }

      if (customers.length && recipientNumber) {
        let matchedCustomer = customers[0];
        let matchedNumber = selectMatchedNumber(
          matchedCustomer,
          recipientNumber,
        );
        let participant = await selectCustomer(matchedCustomer, matchedNumber);

        if (participant && !participant.error && !onPress) {
          await Engage.getConversationByParticipant({
            remote_id: matchedCustomer?.id,
          });
        }
      }

      if (!onPress) await onNewNumberPress();
    },
    [
      recipientNumber,
      customers,
      setConversation,
      setParticipantFromCustomer,
      onNewNumberPress,
      onPress,
    ],
  );

  const showModal =
    recipientNumber?.length > 0 &&
    (customers?.length > 0 ||
      !!conversation?.participant ||
      recipientNumber?.length > 9);

  return (
    <View style={[styles.recipient_search_input__container]}>
      {conversation?.participant && !onPress ? (
        <>
          <RecipientTag
            recipient={conversation?.participant}
            onClear={onResetInput}
          />
          {!conversation?.participant?.valid && (
            <Col rightCenter pr={16}>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size={16}
                color={palette.danger}
              />
            </Col>
          )}
        </>
      ) : (
        <Col>
          <StyledInput
            autoFocus
            placeholder="Type a name or phone"
            blurOnSubmit={false}
            onChangeText={setRecipientNumber}
            onKeyPress={onEnterPress}
            style={[styles.recipient_search_input__input]}
            nativeID="search_input"
          />
          {showModal && (
            <RecipientSuggestionModal
              suggestions={customers}
              term={recipientNumber}
              onPress={onCustomerPress}
              onNewNumberPress={onNewNumberPress}
              showContact={showContact}
              hasOnPress={!!onPress}
            />
          )}
        </Col>
      )}
    </View>
  );
}

const RecipientSuggestionModal = ({
  suggestions,
  onPress,
  onNewNumberPress,
  term,
  showContact = true,
  hasOnPress = false,
}) => {
  if (!suggestions) return null;

  return (
    <View style={[styles.recipient_suggestion_modal__container]}>
      <Text style={[styles.recipient_suggestion_modal__label]}>Customers</Text>
      {suggestions.length === 0 && !hasOnPress && (
        <SendToSuggestion term={term} onPress={onNewNumberPress} />
      )}
      {suggestions.map((customer, x) => {
        const matchedNumber = selectMatchedNumber(customer, term);
        if (!matchedNumber) return null;
        return (
          <RecipientSuggestion
            key={x}
            recipient={customer}
            onPress={onPress(customer, matchedNumber)}
            phone={matchedNumber.phone_number}
            phoneType={matchedNumber.numberType}
            showContact={showContact}
          />
        );
      })}
    </View>
  );
};

export const RecipientTag = ({ recipient, onClear }) => {
  const recipientTitle = recipient.name;

  return (
    <View style={[styles.recipient_search_input__recipient]}>
      <Row center>
        <Text style={[styles.recipient_search_input__recipient_text]}>
          {recipientTitle}
        </Text>
        <IconButton
          faPro
          faProIcon={faXmark}
          iconSize={20}
          iconColor={palette.dark}
          width={24}
          height={24}
          onPress={onClear}
        />
      </Row>
    </View>
  );
};

const SendToSuggestion = ({ term, onPress }) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.recipient_suggestion_modal__item]}>
        <Row center style={{ height: "100%" }}>
          <Col maxWidth={50}>
            <IconButton
              faPro
              faProIcon={faComments}
              color={palette.primary}
              border
            />
          </Col>
          <Col pl={5}>
            <Text
              numberOfLines={1}
              style={[styles.recipient_suggestion_modal__item_label]}
            >
              Send to {term}
            </Text>
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const RecipientSuggestion = ({
  recipient,
  phone,
  phoneType,
  onPress,
  showContact = true,
}) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.recipient_suggestion_modal__item]}>
        <Row>
          <Col leftCenter maxWidth={50}>
            <Avatar
              first_name={recipient.first_name}
              last_name={recipient.last_name}
              size={40}
            />
          </Col>
          <Col leftCenter pl={5}>
            <Text
              numberOfLines={1}
              style={[styles.recipient_suggestion_modal__item_name]}
            >
              {recipient.first_name} {recipient.last_name}
            </Text>
            {showContact && phone && (
              <Text
                numberOfLines={1}
                style={[styles.recipient_suggestion_modal__item_number]}
              >
                {phone}
                &middot; {phoneType}
              </Text>
            )}
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const selectMatchedNumber = (item, term) => {
  if (!item.phone && !item.alt_phone && !item.secondary_phone) return null;

  if (item.phone?.replace(/[^\d.]/g, "").indexOf(term) > -1) {
    return {
      numberType: "Primary",
      phone_number: item.phone?.replace(/[^\d.]/g, ""),
    };
  } else if (item.secondary_phone?.replace(/[^\d.]/g, "").indexOf(term) > -1) {
    return {
      numberType: "Secondary",
      phone_number: item.secondary_phone?.replace(/[^\d.]/g, ""),
    };
  } else if (item.alt_phone?.replace(/[^\d.]/g, "").indexOf(term) > -1) {
    return {
      numberType: "Secondary",
      phone_number: item.alt_phone?.replace(/[^\d.]/g, ""),
    };
  } else {
    return {
      numberType: "Primary",
      phone_number: item.phone?.replace(/[^\d.]/g, ""),
    };
  }
};

const styles = StyleSheet.create({
  recipient_search_input__container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  recipient_search_input__recipient: {
    borderRadius: 24,
    backgroundColor: palette.white,
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  recipient_search_input__recipient_text: {
    fontWeight: "500",
    fontSize: 16,
    marginRight: 8,
    color: palette.dark,
  },
  recipient_suggestion_modal__container: {
    position: "absolute",
    padding: 16,
    top: 45,
    left: 0,
    maxHeight: 240,
    width: 320,
    backgroundColor: palette.white,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.12,
    shadowRadius: 11.62,
    elevation: 4,
    borderRadius: 4,
    zIndex: 1000,
  },
  recipient_suggestion_modal__label: {
    // textTransform: 'uppercase',
    fontWeight: "500",
    color: palette.grey,
    fontSize: 16,
    marginBottom: 8,
  },
  recipient_suggestion_modal__item: {
    paddingVertical: 12,
    cursor: "pointer",
  },
  recipient_suggestion_modal__item_label: {
    fontSize: 16,
    fontWeight: "500",
    color: palette.black,
  },
  recipient_suggestion_modal__item_name: {
    fontSize: 16,
    fontWeight: "500",
    color: palette.black,
  },
  recipient_search_input__input: {
    border: "none",
    width: "100%",
  },
  recipient_suggestion_modal__item_number: {
    fontSize: 14,
    fontWeight: "500",
    color: palette.grey,
  },
});
