import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import Engage from "~/services/engage";
import { DEVICE_UUID } from "~/constants/app";
import { Dynatrace } from "@dynatrace/react-native-plugin";

import {
  persistEngageUserAsync,
  loadEngageUserAsync,
  clearAsync,
} from "../helpers/cache.native";
import FieldPulse from "../services/fieldpulse";
import { Platform } from "react-native";
import {
  USER_ROLE_TEAM_MANAGER,
  USER_ROLE_TEAM_MANAGER_ADMIN,
} from "../constants/users";
import {
  CAMPAIGN_STATUS_KEY,
  CAMPAIGN_STATUS_NOT_SUBMITTED,
  CAMPAIGN_STATUS_VERIFIED,
} from "../constants/businessInformation";
import { WebAppPostMessage } from "../models/WebAppPostMessage";
import DayJs from "../helpers/day";
import { WORKING_HOURS_ENABLED_KEY } from "../constants/workingHours";
import { APP_ENV } from "../constants/app/index.web";
import User from "../models/userModel";

const UserContext = createContext({});

export function UserProvider({ value, children, onReady }) {
  const [user, setUser] = useState(value);
  const [fpUser, setFpUser] = useState({});
  const [cacheLoading, setCacheLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [company, setCompany] = useState({});
  const [isCampaignVerified, setIsCampaignVerified] = useState();
  const [fetchingCampaignStatus, setFetchingCampaignStatus] = useState(true);
  const [previousStatus, setPreviousStatus] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState(false);
  const [businessModelVisible, setBusinessModelVisible] = useState(false);
  const [webappToken, setWebappToken] = useState(null);
  const [saving, setSaving] = useState(false);
  const [logoutError, setLogoutError] = useState(false);
  const userModel = new User(user);

  const canSeeCustomerContact = useMemo(() => {
    return fpUser?.is_able_to_view_customer_contact_info ?? true;
  }, [!!fpUser?.id, fpUser?.id, fpUser?.is_able_to_view_customer_contact_info]);

  const [isUserOutsideWorkingHours, setIsUserOutsideWorkingHours] =
    useState(false);
  const [isWorkingHoursAlertVisible, setIsWorkingHoursAlertVisible] =
    useState(true);
  const loadUser = useCallback(async () => {
    setCacheLoading(true);
    let cachedUser = await loadEngageUserAsync();
    cachedUser && setUser({ ...cachedUser });
    setCacheLoading(false);
    return cachedUser;
  }, [setUser]);

  const updateUser = useCallback(
    (data) => {
      if (!data) return false;
      const userData = { ...user, ...data };
      setUser(userData);
      persistEngageUserAsync(userData);
    },
    [user, setUser],
  );

  const getUser = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      const res = await Engage.getUser();
      const fpRes = await FieldPulse.getUser();
      if (res.error || fpRes.status === 401) {
        console.error(res.error, fpRes.status);
        await logout(true);
      } else {
        setUser(res.response);
        persistEngageUserAsync(res.response);
        setFpUser(fpRes.response);
      }
      setLoading(false);
    }
  }, [logout, setUser, setLoading, sendLogInStatusEvent]);

  const updateSettings = useCallback(
    async (settings) => {
      try {
        setSaving(true);
        if (user) {
          updateUser({ ...user, settings });

          if (typeof Engage.updateUser === "function") {
            await Engage.updateUser({
              id: user.id,
              settings: { ...user?.settings, ...settings },
            });
          }
        }
      } finally {
        setSaving(false);
      }
    },
    [updateUser],
  );

  const getCompany = async () => {
    setLoading(false);
    const res = await FieldPulse.getCompany();
    if (!res.error) {
      setCompany(res.response);
    }
    setLoading(true);
  };

  const logout = useCallback(
    async (clear = false) => {
      if (clear) {
        await clearAsync();
        setUser(null);
      } else {
        await clearAsync();
        setUser(null);
      }
      sendLogInStatusEvent(false);
    },
    [DEVICE_UUID, setUser],
  );

  const getCampaignStatus = async () => {
    if (APP_ENV === "production") {
      try {
        setCampaignStatus(false);
        setIsCampaignVerified();
        setFetchingCampaignStatus(true);
        const res = await Engage.getCampaignStatus();
        if (!res.error) {
          setIsCampaignVerified(res?.response?.is_campaign_verified);
          try {
            const campaignStatus = res.response[CAMPAIGN_STATUS_KEY];
            setCampaignStatus(campaignStatus);
          } catch {}
        }
      } catch (e) {
        console.error(e);
      } finally {
        setFetchingCampaignStatus(false);
      }
    } else {
      setFetchingCampaignStatus(false);
      setIsCampaignVerified(true);
      setCampaignStatus(CAMPAIGN_STATUS_VERIFIED);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    (async () => {
      if (user?.id) {
        setReady(false);
        if (Platform.OS !== "web") {
          Dynatrace.identifyUser(user.id);
        } else {
          window.loggedInUser = user.id;
        }
        await getUser();
        await getCompany();
        setReady(true);
      } else {
        setReady(true);
      }
    })();
  }, [user?.id]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (ready && user?.id) {
        (async () => {
          try {
            if (user?.account?.country !== "AU") await getCampaignStatus();
            else setIsCampaignVerified(true);
          } catch {}
        })();
      }
    }, 10);
    return () => clearTimeout(t);
  }, [user?.id, ready]);

  useEffect(() => {
    if (
      campaignStatus === CAMPAIGN_STATUS_NOT_SUBMITTED &&
      isManagerRole() &&
      ready
    ) {
      setBusinessModelVisible(true);
    }
  }, [campaignStatus]);

  const sendLogInStatusEvent = useCallback(
    (status, user) => {
      setPreviousStatus((state) => {
        if (state !== status) {
          try {
            const loggedInStatusEvent = WebAppPostMessage.createUserStatusEvent(
              status,
              user,
            );
            loggedInStatusEvent.emitEvent();
          } catch (e) {
            console.error(e);
          }
          return status;
        }
        return state;
      });
    },
    [setPreviousStatus],
  );

  const isMessageToneEnabled = useMemo(
    () => user?.settings?.incoming_message_tone,
    [user],
  );

  const isManagerRole = () => {
    return (
      fpUser?.role === USER_ROLE_TEAM_MANAGER_ADMIN ||
      fpUser?.role === USER_ROLE_TEAM_MANAGER
    );
  };

  const simplifyWorkingHours = () => {
    const { working_hours: workingHours } = user?.account || {
      working_hours: [],
    };
    let hours = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    workingHours?.forEach((workingDay, i) => {
      Object.keys(workingDay).forEach((day) => {
        const index = (i + 1) % 7;
        hours[index] = workingDay[day];
      });
    });
    return hours;
  };

  const checkIfUserIsInWorkingHours = () => {
    let today = DayJs();
    today = today.set("second", 0);
    const workingHours = simplifyWorkingHours();
    setIsUserOutsideWorkingHours(false);

    if (user?.account?.[WORKING_HOURS_ENABLED_KEY] && workingHours?.length) {
      const day = today?.day();
      let isInWorkingHours = null;
      const timezone = user?.account?.timezone || "0.0";
      const isNegative = parseInt(timezone) < 0;
      const timezoneAbsolute = Math.abs(timezone);
      let minutes = (timezoneAbsolute - Math.floor(timezoneAbsolute)) * 60;
      let hours = Math.floor(timezoneAbsolute);
      if (!isNegative) {
        hours = -hours;
        minutes = -minutes;
      }

      typeof workingHours?.[day] === "object" &&
        workingHours?.[day].forEach((hour) => {
          if (isInWorkingHours) return;
          const { start, finish } = hour;
          const startHour = parseInt(start.slice(0, 2)) + hours;
          const startMin = parseInt(start.slice(2, 4)) + minutes;
          const endHour = parseInt(finish.slice(0, 2)) + hours;
          const endMin = parseInt(finish.slice(2, 4)) + minutes;

          let startDate = DayJs();
          startDate = startDate
            .utc()
            .set("hour", startHour)
            .set("minute", startMin)
            .set("second", 0)
            .local();

          let endDate = DayJs();
          endDate = endDate
            .utc()
            .set("hour", endHour)
            .set("minute", endMin)
            .set("second", 0)
            .local();

          if (today?.isAfter(startDate) && today?.isBefore(endDate)) {
            isInWorkingHours = true;
          } else {
            isInWorkingHours = false;
          }
        });

      if (
        typeof workingHours?.[day] === "object" &&
        workingHours?.[day]?.length === 0
      ) {
        isInWorkingHours = false;
      }

      if (isInWorkingHours !== null) {
        setIsUserOutsideWorkingHours(!isInWorkingHours);
        return;
      } else {
        setIsUserOutsideWorkingHours(false);
        return;
      }
    }

    setIsUserOutsideWorkingHours(false);
  };

  useEffect(() => {
    if (user?.id && isWorkingHoursAlertVisible) {
      try {
        checkIfUserIsInWorkingHours();
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    user?.id,
    user?.account?.working_hours,
    user?.account?.timezone,
    isWorkingHoursAlertVisible,
  ]);

  return (
    <UserContext.Provider
      value={{
        loggedIn: !!user?.id,
        logout,
        user,
        fpUser,
        getUser,
        updateUser,
        updateSettings,
        canSeeCustomerContact,
        loading,
        isDisturbMode: userModel.isDisturbMode,
        ready,
        cacheLoading,
        isMessageToneEnabled,
        company,
        isCampaignVerified,
        fetchingCampaignStatus,
        isManagerRole,
        company,
        businessModelVisible,
        setBusinessModelVisible,
        campaignStatus,
        getCampaignStatus,
        webappToken,
        setWebappToken,
        sendLogInStatusEvent,
        isUserOutsideWorkingHours,
        isWorkingHoursAlertVisible,
        setIsWorkingHoursAlertVisible,
        saving,
        logoutError,
        setLogoutError,
        isAdmin: fpUser?.role === USER_ROLE_TEAM_MANAGER_ADMIN,
        hasFlicentCampaign: userModel.hasFlicentCampaign,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
