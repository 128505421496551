import { View, StyleSheet, Image } from "react-native";
import StyledInput from "../StyledInput";
import AppContext from "../../contexts/AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import palette from "../../styles/palette";
import { Option } from "../TopBar/index.web";
import { ScrollView } from "react-native-web";
const AddressInput = ({
  value = "",
  onChange,
  shortNameForState = false,
  defaultCountry = "US",
  extras = false,
  ...props
}) => {
  const { googleLibraryLoaded } = useContext(AppContext);
  const [input, setInput] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(false);
  const predictionsRef = useRef();

  useEffect(() => {
    setInput(value || "");
  }, [value]);

  const handleAutoCompletePlaceSelected = (placeId) => {
    if (window.google) {
      const PlacesService = new window.google.maps.places.PlacesService(
        predictionsRef.current,
      );
      try {
        PlacesService.getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (place) => {
            if (place) {
              getAddressData(place);
              setSuggestions([]);
            }
          },
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getSuggestionsCallback = (predictions, status) => {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      setSuggestions(predictions);
    }
  };

  const getSuggestions = () => {
    try {
      let service = new google.maps.places.AutocompleteService();
      if (input && input.trim() && input.trim().length > 4)
        service.getQueryPredictions({ input }, getSuggestionsCallback);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    let address = {
      street: input,
      state: "",
      city: "",
      zip: "",
    };

    if (extras) {
      address.country_code = defaultCountry;
    }

    if (error) {
      onChange({ ...address, suggested: false });
      setError(false);
    }

    if (input?.length < 5) onChange({ address, suggested: false });
  }, [input, error]);

  useEffect(() => {
    let t;
    let address = {
      street: input,
      state: "",
      city: "",
      zip: "",
      suggested: false,
    };
    if (extras) {
      address.country_code = defaultCountry;
    }
    onChange(address);
    if (input && input.trim()) {
      t = setTimeout(() => {
        getSuggestions();
      }, 200);
    }

    return () => {
      if (t) clearTimeout(t);
    };
  }, [input]);

  const getAddressData = (place) => {
    let address = {
      street: "",
      state: "",
      city: "",
      zip: "",
    };
    if (extras) {
      address.country_code = defaultCountry;
    }
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address.street = `${component.long_name} `;
          break;
        }

        case "route": {
          address.street += component.short_name;
          break;
        }

        case "postal_code": {
          address.zip = `${component.long_name}`;
          break;
        }

        case "locality":
          address.city = component.long_name;
          break;

        case "administrative_area_level_1": {
          address.state = shortNameForState
            ? component.short_name
            : component.long_name;
          break;
        }

        case "country":
          if (extras) address.country_code = component.short_name;
          break;
      }
    }
    onChange({ ...address, suggested: true });
  };

  if (!googleLibraryLoaded) return null;

  return (
    <View style={{ zIndex: 50 }}>
      <StyledInput
        value={input}
        lessPadding
        bold
        autoComplete="none"
        onChangeText={setInput}
        onFocus={() => {
          setTimeout(() => {
            setFocused(true);
          }, 100);
        }}
        onBlur={() => {
          setTimeout(() => {
            setFocused(false);
          }, 100);
        }}
        {...props}
      />
      <div ref={predictionsRef}></div>
      {input.length > 4 && input.trim().length > 4 && focused && (
        <ScrollView
          style={{
            zIndex: 50,
            position: "absolute",
            top: "100%",
            backgroundColor: palette.white,
            width: "100%",
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: palette.light_grey,
            borderTopWidth: 0,
          }}
        >
          {suggestions.map((item) => {
            return (
              <Option
                label={item?.description}
                key={item?.place_id}
                onPress={() => handleAutoCompletePlaceSelected(item?.place_id)}
              />
            );
          })}
          {suggestions?.length > 0 && (
            <View
              style={{
                padding: 10,
              }}
            >
              <Image
                source={require("~/assets/powered-by-google.png")}
                style={{
                  height: 11,
                  width: 82,
                  position: "absolute",
                  right: 2,
                  bottom: 2,
                }}
              />
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default AddressInput;
