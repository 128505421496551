import { useCallback, useContext, useEffect, useMemo } from "react";
import useTwilio from "../../hooks/useTwilio";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import palette from "../../styles/palette";
import IncomingCallModal from "../IncomingCallModal/index.web";
import ConversationContext from "../../contexts/ConversationContext";
import FieldPulse from "../../services/fieldpulse";
import Engage from "../../services/engage";
import { MAIN_WEBAPP_URLS } from "../../constants/app/index.web";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/UserContext";

export default function IncomingCallsPanel() {
  const {
    callInvites,
    acceptCall,
    endCall,
    rejectCallInvite,
    isMuted,
    toggleMute,
    activeCall,
    startCall,
  } = useTwilio();
  const history = useHistory();

  const { setParticipantFromCustomer, setConversation, clearConversation } =
    useContext(ConversationContext);
  const { fpUser, sendLogInStatusEvent, setLogoutError } =
    useContext(UserContext);

  const sortedCallInvites = useMemo(() => {
    if (Object.values(callInvites).length < 2) {
      return Object.values(callInvites);
    }
    return Object.values(callInvites).sort((a, b) => {
      return a?.timestamp - b?.timestamp;
    });
  }, [JSON.stringify(callInvites)]);

  const openConversation = async (customer_id) => {
    clearConversation();
    const customer = await FieldPulse.getCustomer(customer_id);
    if (!customer.error) {
      await setParticipantFromCustomer(
        customer?.response,
        customer?.response.phone_e164,
      );
      const res = await Engage.getConversationByParticipant({
        remote_id: customer_id,
      });

      if (!res.error) {
        setConversation(res.response);
        return;
      }
    }
  };

  const onLogoutUser = () => {
    setLogoutError(true);
    history?.push("/logout");
  };

  const handlePostMessage = async (e) => {
    if (MAIN_WEBAPP_URLS.includes(e?.origin)) {
      const payload = e?.data;
      if (!payload || !payload?.type) return;
      const webAppPostMessageEvent = new WebAppPostMessage(
        payload.type,
        payload,
      );
      webAppPostMessageEvent.handleEvent(
        e,
        startCall,
        acceptCall,
        endCall,
        rejectCallInvite,
        toggleMute,
        openConversation,
        onLogoutUser,
      );
    }
  };

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);

    return () => window.removeEventListener("message", handlePostMessage);
  }, [JSON.stringify(callInvites), JSON.stringify(activeCall), isMuted]);

  useEffect(() => {
    sendLogInStatusEvent(true, fpUser);
  }, [fpUser?.id]);

  if (sortedCallInvites?.length === 0) return null;

  return (
    <ScrollView
      style={{
        position: "absolute",
        right: 10,
        zIndex: 1000,
        top: 70,
        width: "25%",
        maxWidth: 300,
      }}
    >
      <FlatList
        data={sortedCallInvites}
        keyExtractor={(item) => item?.call?.parameters?.CallSid}
        renderItem={(props) => (
          <IncomingCallModal
            callItem={props?.item?.call}
            index={props?.index}
          />
        )}
      />
    </ScrollView>
  );
}
