import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import UserContext from "~/contexts/UserContext";
import useTwilioInit from "~/hooks/useTwilioInit/index.web";
import { useCallDuration } from "~/hooks/useCallDuration";
import NumbersContext from "~/contexts/NumbersContext";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import {
  CALL_EVENT_TYPE,
  CALL_MUTE_COMMAND,
} from "../../constants/webapp-integration";

export const TwilioContext = createContext({});

export function TwilioProvider({ children }) {
  const { user } = useContext(UserContext);
  const { activeNumber } = useContext(NumbersContext);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const {
    device,
    activeCall,
    callState,
    callStatus,
    token,
    dispatch,
    startDeviceCall,
    endDeviceCall,
    callInvites,
    acceptedCalls,
    acceptCall: acceptDeviceCall,
    rejectCallInvite,
    getCallData,
    ignoredCalls,
  } = useTwilioInit(user?.id);

  const [destination, setDestination] = useState({});
  const [callQueue, setCallQueue] = useState();

  // Function that starts an outgoing call
  const startCall = useCallback(
    async (to, metaData = {}, fromParam) => {
      let from = fromParam;
      const { phone_number } = activeNumber;
      if (!from) {
        from = phone_number;
      }

      if (from) {
        try {
          setDestination({ phoneNumber: to, participant: { ...metaData } });
          startDeviceCall({
            to,
            from,
            data: {
              direction: "outgoing",
              phoneNumber: to,
              participant: { ...metaData },
            },
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        console.error("Attempting to make call with no assignedNumber ");
      }
    },
    [activeNumber, startDeviceCall],
  );

  const toggleMute = useCallback(
    (mute) => {
      if (activeCall) {
        setIsMuted(mute);
        activeCall.mute(mute);
      } else {
        console.error("Cannot mute call without an active call");
      }
    },
    [activeCall],
  );

  // Not needed for web. NOOP
  const toggleSpeaker = useCallback(() => {}, []);

  const sendDigits = useCallback(
    (digits) => {
      if (activeCall) {
        activeCall.sendDigits(`${digits}`);
      } else {
        console.warn("Cannot send digits without an active call");
      }
    },
    [activeCall],
  );

  const toggleHold = useCallback(() => {
    if (activeCall) {
      setIsOnHold((hold) => !hold);
      // Add api request
    } else {
      console.warn("Cannot put call on hold without an active call");
    }
  }, [activeCall]);

  const transferCall = useCallback(
    (to) => {
      if (activeCall) {
        // Add api request
      } else {
        console.warn("Cannot transfer call without an active call");
      }
    },
    [activeCall],
  );

  // Function that ends a call
  const endCall = useCallback(async () => {
    try {
      setDestination({});
      await endDeviceCall();
    } catch (e) {
      console.log(e);
    }
  }, [device, activeCall, endDeviceCall]);

  const acceptCall = (params) => {
    try {
      setDestination({});
      acceptDeviceCall(params);
    } catch (e) {}
  };

  const { duration, callDuration } = useCallDuration(callState);

  useEffect(() => {
    if (!activeCall && isMuted) setIsMuted(false);
    if (!activeCall && isOnHold) setIsOnHold(false);
  }, [activeCall]);

  useEffect(() => {
    try {
      const webappPostMessageEvent = new WebAppPostMessage(CALL_EVENT_TYPE);
      webappPostMessageEvent.command = CALL_MUTE_COMMAND;
      webappPostMessageEvent.data = !isMuted;
      webappPostMessageEvent.emitEvent();
    } catch (e) {
      console.error(e);
    }
  }, [isMuted]);

  return (
    <TwilioContext.Provider
      value={{
        destination,
        callState,
        activeCall,
        startCall,
        endCall,
        token,
        sendDigits,
        toggleMute,
        toggleSpeaker,
        duration,
        callDuration,
        callStatus,
        isMuted,
        callQueue,
        setCallQueue,
        callInvites,
        acceptedCalls,
        acceptCall,
        rejectCallInvite,
        isOnHold,
        toggleHold,
        getCallData,
        ignoredCalls,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
}

export default TwilioContext;
