import React, { useState, useContext, useCallback, useEffect } from "react";

import { ActivityIndicator, View, FlatList } from "react-native";

import palette from "~/styles/palette";

import Col from "~/layouts/Col";
import Row from "~/layouts/Row";

import TeamContext from "~/contexts/TeamContext";
import NumberContext from "~/contexts/NumberContext";

import Engage from "~/services/engage";
import Modal from "../Modal";
import { RenderUserList, TeamLabel } from "../AssignmentModal/index.web";

export const NumberManagementModal = ({}) => {
  const { loading, number, getNumber } = useContext(NumberContext);

  console.log("[NumberManagementModal]");

  const onAssignManagerHandler = useCallback(
    (user) => async () => {
      let copy = number.managers.concat();
      // const removed = copy.filter((a) => user.id !== a.user_id);
      if (!copy.find((u) => u.id === user.id)) {
        copy = copy.concat({
          user,
          user_id: user.id,
        });
        await Engage.grantNumberAccess({ id: number.id, user_id: user.id });
      } else {
        copy = copy.filter((a) => user.id !== a.user_id);
        await Engage.revokeNumberAccess({ id: number.id, user_id: user.id });
      }
      await getNumber();
    },
    [number, number.id, getNumber],
  );

  return (
    <Modal title="Add Managers" fixed>
      <Col
        style={{
          flex: 1,
        }}
      >
        <Row pt={8} left>
          <TeamList
            managers={number.managers}
            onPress={onAssignManagerHandler}
          />
        </Row>
      </Col>
    </Modal>
  );
};

const TeamList = ({ managers, onPress }) => {
  const { refresh, loading } = useContext(TeamContext);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    let t;
    t = setTimeout(() => {
      if (!loading) {
        setLoadingPage(false);
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [loading]);

  if (loadingPage)
    return (
      <View
        style={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={palette.primary} />
      </View>
    );

  return (
    <FlatList
      ListHeaderComponent={<Managers managers={managers} onPress={onPress} />}
      ListFooterComponent={<OtherUsers managers={managers} onPress={onPress} />}
    />
  );
};
const Managers = ({ managers, onPress }) => {
  return (
    <Row>
      <Col>
        <Row>
          <TeamLabel title="Managers" />
        </Row>
        <RenderUserList
          data={managers}
          active={managers}
          canLoadMore={false}
          onLoadMore={null}
          onPress={onPress}
          loadingMoreUsers={false}
        />
      </Col>
    </Row>
  );
};

const OtherUsers = ({ managers, onPress }) => {
  const { users, loadMoreUsers, loadingMoreUsers, disabled } =
    useContext(TeamContext);

  return (
    <Row>
      <Col>
        <Row>
          <TeamLabel title="Other Users" />
        </Row>
        <RenderUserList
          data={users?.filter((u) => !managers?.find((a) => a?.id === u?.id))}
          active={managers}
          canLoadMore={!disabled}
          onLoadMore={loadMoreUsers}
          onPress={onPress}
          loadingMoreUsers={loadingMoreUsers}
        />
      </Col>
    </Row>
  );
};
