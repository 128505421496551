import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import FieldPulse, { hasToken } from "../services/fieldpulse";
import UserContext from "~/contexts/UserContext";
import { filterCustomer } from "~/helpers/customers";

const CustomersContext = createContext({
  loading: false,
  error: false,
  customers: [],
  limit: 50,
  page: 1,
  total: 0,
  active: null,
});

// NOTE: https://medium.com/@srbkrishnan/infinite-scroll-pagination-in-flatlist-with-hooks-and-function-components-c9c08bba23a8
const FPCustomerRequest = {
  all: FieldPulse.getCustomers,
  recent: FieldPulse.getRecentCustomers,
  assigned: FieldPulse.getAssignedCustomers,
};

export function CustomersProvider({
  filterBy = "recent",
  children,
  isWeb = false,
}) {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    filter: filterBy,
    limit: 50,
    // TODO: Fix this once we have the api returning the correct results
    total: 0,
    customers: [],
  });

  const { limit, filter } = state;
  const page = useRef(1);

  const getCustomers = useCallback(
    async (filterParam) => {
      if (filterParam) {
        updateFilter(filterParam);
      }

      const activeFilter = filterParam || filter;

      if (!loading && user?.remote_id) {
        setLoading(true);
        const res = await FPCustomerRequest[activeFilter]({
          page: page.current,
          limit,
          user_id: user?.remote_id,
          // validPhone: true
        });
        if (!res.error && res.response) {
          setState((state) => ({
            ...state,
            customers: isWeb
              ? res.response.map(filterCustomer)
              : page.current === 1
              ? res.response.map(filterCustomer)
              : [...state.customers, ...res.response.map(filterCustomer)],
            total: res.total_results,
          }));
        } else {
          setError(res.message);
        }
        setLoading(false);
      }
    },
    [user?.remote_id, filter, limit, updateFilter, setLoading],
  );

  const updateFilter = useCallback(
    (filterBy) => {
      if (loading) return;
      setState({
        ...state,
        filter: filterBy,
        customers: [],
      });
      page.current = 1;
    },
    [state, loading],
  );

  const setSearchSuggestions = useCallback(
    (suggestions) => {
      setState({
        ...state,
        customers: suggestions,
        total: suggestions.length,
      });
    },
    [state],
  );

  const prevPage = async () => {
    if (page.current === 1) return;
    page.current = page.current - 1;
    await getCustomers();
  };

  const nextPage = async () => {
    page.current = page.current + 1;
    await getCustomers();
  };

  const refreshCustomers = async () => {
    setState({ ...state, customers: [] });
    page.current = 1;
    await getCustomers();
  };

  useEffect(() => {
    if (!loading) {
      getCustomers();
    }
  }, [limit, filter]);

  const deleteCustomer = async (id) => {
    setLoading(true);
    const res = await FieldPulse.deleteCustomer({ id });
    await refreshCustomers();
    setLoading(false);
    return res;
  };

  return (
    <CustomersContext.Provider
      value={{
        ...state,
        page: page.current,
        loading,
        error,
        setSearchSuggestions,
        getCustomers,
        nextPage,
        prevPage,
        filter,
        updateFilter,
        refreshCustomers,
        deleteCustomer,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
}

export default CustomersContext;
