import { StyleSheet, Text, View } from "react-native";
import useTwilio from "../../../hooks/useTwilio";
import Heading from "../../Heading/index.web";
import palette from "../../../styles/palette";

import { TouchableOpacity } from "react-native-web";
import { useContext, useState } from "react";
import { Option } from "../index.web";
import UserContext from "../../../contexts/UserContext";

export function OtherCallsContainer() {
  const { ignoredCalls, acceptCall, rejectCallInvite, activeCall } =
    useTwilio();
  const { canSeeCustomerContact } = useContext(UserContext);
  const [openMenuId, setOpenMenuId] = useState(null);

  if (!ignoredCalls?.length) return null;

  return (
    <View style={styles.other_calls_container}>
      {ignoredCalls?.slice(0, 4)?.map((call) => {
        return (
          <CallItem
            key={call?.call?.parameters?.CallSid}
            call={call?.call}
            acceptCall={acceptCall}
            rejectCallInvite={rejectCallInvite}
            openMenuId={openMenuId}
            setOpenMenuId={setOpenMenuId}
            activeCall={!!activeCall}
            showContact={canSeeCustomerContact}
          />
        );
      })}
    </View>
  );
}

const CallItem = ({
  call,
  acceptCall,
  rejectCallInvite,
  openMenuId,
  setOpenMenuId,
  activeCall,
  showContact = true,
}) => {
  const id = call?.parameters?.CallSid;
  const getParticipant = () => {
    let res = {
      phone_number: "",
      customer_id: null,
      name: "",
      participant_id: null,
    };

    if (!res?.phone_number && call?.parameters?.From) {
      res.phone_number = call?.parameters?.From;
    }

    if (
      call?.customParameters &&
      call?.customParameters.has("participantName")
    ) {
      res.name = call?.customParameters?.get("participantName");
    }

    if (!res.name && call) {
      res.name = "Unknown Caller";
    }

    if (
      !res.customer_id &&
      call?.customParameters?.has("participantRemoteId")
    ) {
      res.customer_id = call?.customParameters?.get("participantRemoteId");
    }

    if (!res?.participant_id && call?.customParameters?.has("participantId")) {
      res.participant_id = call?.customParameters?.get("participantId");
    }

    return res;
  };

  const onAcceptIncomingCall = () => {
    acceptCall(id);
  };

  const onRejectIncomingCall = () => {
    rejectCallInvite(id);
  };

  const participant = getParticipant();

  const onPressHandler = () =>
    setOpenMenuId((openMenuId) => {
      if (openMenuId === id) {
        return null;
      }
      return id;
    });

  const menuOpen = openMenuId === id;

  return (
    <TouchableOpacity onPress={onPressHandler}>
      <View style={styles.call_item}>
        <Heading
          size={6}
          color={palette.white}
          mb={0}
          mt={0}
          textStyle={styles.caller_name}
          numberOfLines={1}
          style={{
            maxWidth: 120,
          }}
        >
          {participant?.customer_id ? participant?.name : "Unknown"}
        </Heading>
        {showContact && (
          <Text style={styles.caller_phone}>{participant?.phone_number}</Text>
        )}
        <Heading
          size={6}
          color={palette.white}
          mb={0}
          mt={0}
          textStyle={styles.call_status}
        >
          Silenced
        </Heading>
      </View>
      <CallMenu
        open={menuOpen}
        onAccept={onAcceptIncomingCall}
        onReject={onRejectIncomingCall}
        activeCall={activeCall}
      />
    </TouchableOpacity>
  );
};

const CallMenu = ({ open, onAccept, onReject, activeCall }) => {
  if (!open) return null;
  return (
    <View
      style={{
        backgroundColor: palette.white,
        zIndex: 200,
        position: "absolute",
        top: "120%",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: palette.light_grey,
        width: "100%",
      }}
    >
      <Option
        label={activeCall ? "End And Accept Call" : "Accept Call"}
        onPress={onAccept}
      />
      <Option label="End Call" onPress={onReject} />
    </View>
  );
};

const styles = StyleSheet.create({
  other_calls_container: {
    flexDirection: "row",
    marginLeft: 16,
    zIndex: 200,
    gap: 8,
  },
  call_item: {
    backgroundColor: palette.grey_blue,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingHorizontal: 16,
    height: "90%",
    justifyContent: "center",
    paddingVertical: 6,
    gap: 2,
    cursor: "pointer",
    textAlign: "center",
    minWidth: 120,
  },
  caller_name: {
    fontWeight: "600",
    fontSize: 12,
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  call_status: {
    fontWeight: "600",
    fontSize: 10,
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  caller_phone: { color: palette.white, fontSize: 10, textAlign: "center" },
});
