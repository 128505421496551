import React, { useEffect, useState, useContext, createContext } from "react";

import platform from "platform";
import Constants from "expo-constants";
import useWebPush from "../hooks/useWebPush";
import Engage from "../services/engage";
import UserContext from "./UserContext";
import { DEVICE_UUID } from "../constants/app";

const DEFAULT_BROWSER = {
  expo_version: Constants.expoVersion,
  app_version: Constants.manifest.version,
  platform: "web",
  model_type: `${platform.os.family} ${platform.name}`,
  os_version: platform.version,
  address_book_enabled: false,
  notifications_enabled: false,
};

const BrowserContext = createContext({});

export const BrowserProvider = ({ children }) => {
  const { loggedIn } = useContext(UserContext);
  const { pushToken, granted, requestPermissions } = useWebPush();

  const [browserDevice, setBrowserDevice] = useState({
    ...DEFAULT_BROWSER,
    uuid: DEVICE_UUID,
    push_token: pushToken,
    notifications_enabled: granted,
  });

  useEffect(() => {
    (async () => {
      if (!pushToken || !granted) {
        const newToken = await requestPermissions();
        if (newToken) {
          setBrowserDevice({
            ...browserDevice,
            push_token: newToken,
            notifications_enabled: true,
          });
        }
      }
    })();
  }, [false]);

  useEffect(() => {
    if (loggedIn) {
      Engage.addDevice(browserDevice);
    }
  }, [!!loggedIn]);

  const logoutDevice = async () => {
    try {
      if (browserDevice?.push_token)
        await Engage.logout(browserDevice?.push_token);
    } catch {}
  };

  return (
    <BrowserContext.Provider value={{ logoutDevice }}>
      {children}
    </BrowserContext.Provider>
  );
};

export default BrowserContext;
